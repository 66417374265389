import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoggedIn: false,
    userData: {},
  },
  getters: {
  },
  mutations: {
    login(state, userData) {
      state.isLoggedIn = true;
      state.userData = userData;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userData = {};
    },
  },
  actions: {
    doLogin({ commit }, userData) {
      // Realiza la lógica de inicio de sesión aquí (por ejemplo, llamando a tu función LoginFB)
      // Luego, llama a la mutación para actualizar el estado
      //console.log("Paso por doLogin");
      commit('login', userData);
    },
    doLogout({ commit }) {
      // Realiza la lógica de cierre de sesión aquí
      // Luego, llama a la mutación para actualizar el estado
      commit('logout');
    },
  },
  modules: {
  }
})
