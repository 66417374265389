<template>
  <div v-if="desserts" class="align-center justify-center mt-n8">
    <!-- TABLA NUEVA -->
    <v-data-table
      :headers="headers2"
      :items="desserts"
      class="mt-3 elevation-4"
      style="background-color: #f8f8f8; border-radius: 10px"
      height="50%"
      fixed-header
    >
      <!--  
      <template v-slot:item="{ item }">
        <tr>
          <td
           :class="item.estadoAdmin  ? 'bg-red' : ''"
            v-for="header in headers2"
            :key="header"
          >
            {{ item.columns[header.key] }}
          </td>
        </tr>
      </template>-->

      <template v-slot:[`item.fechaRegistro`]="{ item }">
        <v-chip
          class="ma-2  mt-3"
          text-color="black"
          variant="outlined"
          color="teal-darken-4"
        >
          {{ convertirTimestampAFechaHora(item.fechaRegistro) }}
        </v-chip>
      </template>

      <template v-slot:[`item.investment`]="{ item }">
        <div style="text-align: end" class="ma-2 mt-3">
          {{ formatCurrency(item.investment) }}
        </div>
      </template>

      <template v-slot:[`item.mes`]="{ item }">
        <div style="text-align: center" class=" mt-3">
          {{ item.mes }}
        </div>
      </template>

      <template v-slot:[`item.dias`]="{ item }">
        <div style="text-align: center" class=" mt-3">
          {{ item.dias }}
        </div>
      </template>

      <template v-slot:[`item.tipoMovimiento`]="{ item }">
        <div style="text-align: center" class=" mt-3">
          {{ item.tipoMovimiento }}
        </div>
      </template>

      <template v-slot:[`item.porcentajeGanancia`]="{ item }">
        <td style="width: 100px">
          <!-- Ajusta el ancho deseado aquí -->
          <v-text-field
            variant="outlined"
            v-model="item.porcentajeGanancia"
            label="Porcentaje %"
            placeholder="0 %"
            type="number"
            class="mt-3 numeric-input"
            color="orange"
            :disabled="
              item.estadoAdmin ||
              item.tipoMovimiento == 'Retirar Capital' ||
              item.tipoMovimiento == 'Retirar Rendimientos'
            "
            
          ></v-text-field>

          
        </td>
      </template>

      <template v-slot:[`item.estadoAdmin`]="{ item, index }">
        <!-- :disabled="item.estadoAdmin" -->
        <v-switch
          @change="
            cambioEstadoTransaccion(
              //item,
              'estadoAdmin',
              index, //index,
              item.estadoAdmin,
              item.porcentajeGanancia,
              item.tipoMovimiento,
              item.rentabilidadTotal
            )
          "
          color="green"
          v-model="item.estadoAdmin"
          class="mt-3"
          :disabled="item.estadoAdmin || (item.porcentajeGanancia <= 0 && (item.tipoMovimiento != 'Retirar Capital' && item.tipoMovimiento != 'Retirar Rendimientos' ))"
        ></v-switch>
      </template>

      <!--

        <template v-slot:[`item.pago`]="{ item }">
          <v-switch
            @change="
              cambioEstadoTransaccion(
                //item,
                'pago',
                index,
                item.pago,
                item.porcentajeGanancia,
                item.tipoMovimiento
              )
            "
            v-model="item.pago"
            color="green"
            :disabled="item.pago"
          ></v-switch>
        </template>

      -->

      <template v-slot:[`item.rentabilidadTotal`]="{ item }">
        <div style="text-align: end" class=" mt-3">
          <v-chip :color="getColor(item.rentabilidadTotal)">
            {{ formatCurrency(item.rentabilidadTotal) }}
          </v-chip>
        </div>
      </template>
      
    </v-data-table>
  </div>
</template>

  

<script>
import { VDataTable } from "vuetify/labs/VDataTable";

import { updateInversionFB } from "@/config/inversiones/editUserTransa.js";

export default {
  name: "tablaPrincipalTrans",
  components: {
    VDataTable,
  },

  props: {
    juego: {
      type: Array,
      default: () => [],
    },
    refreshData: Function, // Recibe la función como propiedad
  },

  data: () => ({
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    headers: [
      {
        title: "Fecha Registro",
        align: "center",
        key: "datos.fechaRegistro",
        sortable: true,
      },
      {
        title: "Descripción",
        align: "center",
        key: "datos.descripcion",
        sortable: false,
      },
      {
        title: "Valor Transacción",
        align: "center",
        key: "datos.investment",
        sortable: true,
      },
      {
        title: "Porcentaje",
        align: "center",
        key: "datos.porcentaje",
        sortable: false,
      },
      {
        title: "Periodo",
        align: "center",
        key: "datos",
        sortable: true,
      },
      {
        title: "Tipo Movimiento",
        align: "center",
        key: "datos.fuente",
        sortable: true,
      },
      {
        title: "Estado",
        align: "center",
        key: "datos.estadoAdmin",
        sortable: true,
      },
    ],
    desserts: [],
    datos: null,
    search: "",

    expanded: [],

    sortBy: [{ key: "fechaRegistro", order: "desc" }],
    headers2: [
      {
        title: "Fecha Registro",
        value: "fechaRegistro",
        sortable: false,
      },
      {
        title: "Mes",
        value: "mes",
        sortable: false,
      },
      {
        title: "Tipo de Movimiento",
        value: "tipoMovimiento",
        sortable: false,
      },
      {
        title: "Rentabilidad",
        value: "rentabilidadTotal",
        sortable: false,
      },
      {
        title: "Días",
        value: "dias",
        sortable: false,
      },
      {
        title: "Porcentaje",
        value: "porcentajeGanancia",
        sortable: false,
      },
      {
        title: "Aprobado",
        value: "estadoAdmin",
        sortable: false,
      },
    ],
    itemsPerPageB: 5,

    selected: [],
    idDoc: "",
  }),

  mounted() {
    this.datos = this.juego;
    //console.log(this.datos);
    //this.desserts = this.datos;
    //this.totalItems = this.desserts.length; // Actualiza el total de elementos

    this.desserts = this.datos[0].datos.rentabilidadMensual;
    this.totalItems = this.desserts.length; // Actualiza el total de elementos

    //console.log(this.datos);
    this.idDoc = this.datos[0].datos.id;

    
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    isDisabled() {
      return (item) => {
        //console.log(item);
        return (
          item.datos.estadoAdmin ||
          !(item.datos.porcentaje > 0) ||
          item.datos.porcentaje < 0
        );
      };
    },
  },

  methods: {
    async estadoTransaction(datosUsuario) {
      //console.log(datosUsuario.datos);

      let json = datosUsuario;

      const resultTransaction = await updateInversionFB(json);

      if (resultTransaction[0]) {
        //console.log(resultTransaction);

        this.refreshData(); //Función para repetir los calculos.
      } else {
        console.log("No actualiza");
      }
    },

    cambioEstadoTransaccion(
      //item,
      propiedad,
      posicion,
      valor,
      porcentaje,
      movimiento,
      rentabilidadTotalGeneral
    ) {
      let idInv = this.idDoc;

      //console.log(item, propiedad, posicion, valor, porcentaje, movimiento, idInv);

      this.$emit("cambioEstados", [
        propiedad,
        idInv,
        posicion,
        valor,
        porcentaje,
        movimiento,
        rentabilidadTotalGeneral
      ]);

      const dessertsLocal = [...this.desserts];
      // Actualiza la referencia de la propiedad con la nueva copia
      this.$emit("update:juego", dessertsLocal);
    },

    actualizarEstadoAdmin(item, nuevoValor) {
      // Encuentra el índice del elemento en tu array
      const index = this.desserts.findIndex(
        (element) => element.datos.id === item.datos.id
      );

      if (index !== -1) {
        // Crea una copia del array para forzar la reactividad
        const dessertsLocal = [...this.desserts];
        // Actualiza el valor de estadoAdmin
        dessertsLocal[index].datos.estado = nuevoValor;
        // Actualiza la referencia de la propiedad con la nueva copia
        this.$emit("update:juego", dessertsLocal);
        // Puedes guardar los cambios en Firebase u otro lugar si es necesario
      }
    },

    changePage() {
      // Lógica para cargar los elementos de la página actual
      // Puedes hacer una solicitud de API, por ejemplo
      console.log(`Cambiando a la página ${this.currentPage}`);
    },

    updateTotalPages(value) {
      this.totalItems = value;
    },

    convertirTimestampAFechaHora(timestamp) {
      const fecha = new Date(timestamp.seconds * 1000); // Multiplicamos por 1000 para convertir segundos a milisegundos
      return fecha.toLocaleString(); // Retorna la fecha en formato legible de acuerdo a la configuración regional
    },

    formatFirebaseTimestamp(timestamp) {
      // Convierte los segundos a milisegundos
      const milliseconds = timestamp.seconds * 1000;

      // Crea una nueva instancia de Date con los milisegundos
      const date = new Date(milliseconds);

      // Obtiene los componentes de la fecha
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Formatea el mes/año
      const formattedDate = `${month}/${year}`;

      return formattedDate;
    },

    formatCurrency(numberLlega) {
      // Si ya es un número, no lo conviertas
      const number =
        typeof numberLlega === "number" ? numberLlega : parseFloat(numberLlega);

      // Verificar si el número es menor que 1 y ajustar el formato
      if (number < 1) {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },

    getColor(valores) {
      if (valores < 0) return "red"; // Si es negativo, se muestra en rojo
      else if (valores > 0)
        return "green"; // Si es positivo, se muestra en verde
      else return "orange"; // Si es cero, se muestra en naranja u otro color deseado
    },

    

  },
};
</script>

<style scoped>
.my-header-style {
  background-color: #f8d07e;
}

.even-row {
  background-color: #ffedc9; /* Color para filas pares */
}

.odd-row {
  background-color: #fff; /* Color para filas impares */
}

.custom-width-class {
  width: 100%;
}

.rounded-dialogo {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 3px; /* Ajusta el relleno según sea necesario */
  color: #f8d07e;
}

/* Ocultar los botones de incremento y decremento en input[type="number"] */
.numeric-input input[type="number"]::-webkit-inner-spin-button,
.numeric-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
  display: none !important;
}

/* También puedes necesitar el siguiente CSS para algunos navegadores */
.numeric-input input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>