<template >
  <div class="is-1 is-mobile ml-2 mb-3 mt-n5">
    <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
      <ul>
        <li class="">
          <span class="icon is-small">
            <i class="fas fa-home" aria-hidden="true"></i>
          </span>
          <router-link :to="{ name: 'Dashboard' }">Inicio</router-link>
        </li>
        <li>
          <a>{{ categoriaActual }} </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "DashboardMiga",

  mounted() {
    //console.log(this.$route.name);
  },

  computed: {
    categoriaActual() {
      // Obtener la categoría actual de la ruta
      switch (this.$route.name) {
        case "Calculadora":
          return this.$route.name || "Inicio";
        //return "Calculadora de Inversión";

        default:
          return this.$route.name || "Inicio";
      }
    },
  },
};
</script>

<style>
/* Estilo para las migajas de pan */
.breadcrumb li a {
  color: #f8d07e; /* Color deseado */
}

/* Estilo para el icono de casa en la primera migaja */
.breadcrumb li:first-child span {
  color: #f8d07e; /* Color deseado */
}

/* Estilo para las migajas de pan en estado hover */
.breadcrumb li a:hover {
  text-decoration: underline; /* Otra opción para indicar hover */
}
</style>