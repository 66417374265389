<template>
  <!--  class="align-center justify-center" -->
  <div v-if="desserts">
    <div >
      <!--  align-center justify-center -->
      <v-row>
        <v-col>
          <v-toolbar class="rounded-dialogo elevation-2">
            <v-text-field
              v-model="search"
              label="Buscar por nombre"
              class="mx-4 mt-2 ml-2"
              variant="plain"
              clearable
              hide-details="auto"
            ></v-text-field>
          </v-toolbar>

          <v-data-table
            class=" mt-2 elevation-2"
            style="background-color: #c0c0c0; border-radius: 10px"
            :headers="headers"
            :items="filteredDesserts"
            item-value="datos"
            :page="currentPage"
            :items-per-page="itemsPerPage"
            :search="search"
            @page-count="updateTotalPages"
          >
            <template v-slot:item="{ item, index }">
              <tr
                :class="{
                  'even-row': index % 2 === 0,
                  'odd-row': index % 2 !== 0,
                }"
              >
                <!-- Agrega las clases de fila alternada -->
                <td v-if="item.datos.fechaRegistro">
                  <!-- {{ convertirTimestampAFechaHora(item.datos.fechaRegistro) }} -->
                  <DialogoUsuario
                    :selectedItem="item"
                    :refreshData="refreshData"
                    @open-dialog="openDialog(index)"
                  />
                </td>
                <td v-if="item.datos.nombres || item.datos.email">
                  {{
                    item.datos.nombres ? item.datos.nombres : item.datos.email
                  }}
                </td>
                <td v-if="item.datos.estado">
                  {{ item.datos.estado ? "Aprobado" : "Pendiente" }}
                </td>
                <td v-if="item.datos.estadoAdmin || !item.datos.estadoAdmin">
                  {{ item.datos.estadoAdmin ? "Aprobado" : "Pendiente" }}
                </td>
                <!--
                <td>
                  {{ item.datos.alert }}
                </td>
              --></tr>
            </template>

            <template v-slot:header="{ header }">
              <th
                :class="{ 'custom-header': true, separator: true }"
                style="background-color: #b07200; vertical-align: middle"
              >
                {{ header.text }}
              </th>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

  

<script>
import { VDataTable } from "vuetify/labs/VDataTable";
import DialogoUsuario from "./DialogAd.vue";

export default {
  name: "tablaPrincipal",
  components: {
    VDataTable,
    DialogoUsuario,
  },

  props: {
    juego: {
      type: Array,
      default: () => [],
    },
    refreshData: Function, // Pasa la función como propiedad
  },

  data: () => ({
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    headers: [
      {
        title: "Perfil",
        align: "center",
        key: "datos.fechaRegistro",
        sortable: true,
      },
      {
        title: "Nombres",
        text: "Buscar",
        key: "datos.nombres",
        sortable: true,
      },
      { title: "edo. Usuario", key: "datos.estado" },
      { title: "edo. Admin", key: "datos.estadoAdmin" },
      //{ title: "Alerta", key: "datos.alert" },
    ],
    desserts: [],
    datos: null,
    search: "", // Nuevo dato para el campo de búsqueda
  }),

  mounted() {
    this.datos = this.juego;
    //console.log(this.datos);
    this.desserts = this.datos;
    this.totalItems = this.desserts.length; // Actualiza el total de elementos
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    filteredDesserts() {
      return this.desserts.filter((item) => {
        const lowerSearch = this.search ? this.search.toLowerCase() : ""; // Verifica si search es nulo o indefinido
        // Verifica que item y item.datos no sean undefined antes de acceder a las propiedades
        if (item && item.datos) {
          // Filtra por la cadena de búsqueda en el nombre o el email
          return (
            (item.datos.nombres &&
              item.datos.nombres.toLowerCase().includes(lowerSearch)) ||
            (item.datos.email &&
              item.datos.email.toLowerCase().includes(lowerSearch))
          );
        }
        return false; // Si item o item.datos son undefined, excluimos este elemento
      });
    },
  },

  methods: {
    actualizarEstadoAdmin(item, nuevoValor) {
      // Encuentra el índice del elemento en tu array
      const index = this.desserts.findIndex(
        (element) => element.datos.id === item.datos.id
      );

      if (index !== -1) {
        // Crea una copia del array para forzar la reactividad
        const dessertsLocal = [...this.desserts];
        // Actualiza el valor de estadoAdmin
        dessertsLocal[index].datos.estado = nuevoValor;
        // Actualiza la referencia de la propiedad con la nueva copia
        this.$emit("update:juego", dessertsLocal);
        // Puedes guardar los cambios en Firebase u otro lugar si es necesario
      }
    },

    updateTotalPages(value) {
      this.totalItems = value;
    },

    convertirTimestampAFechaHora(timestamp) {
      const fecha = new Date(timestamp.seconds * 1000); // Multiplicamos por 1000 para convertir segundos a milisegundos
      return fecha.toLocaleString(); // Retorna la fecha en formato legible de acuerdo a la configuración regional
    },

    formatFirebaseTimestamp(timestamp) {
      // Convierte los segundos a milisegundos
      const milliseconds = timestamp.seconds * 1000;

      // Crea una nueva instancia de Date con los milisegundos
      const date = new Date(milliseconds);

      // Obtiene los componentes de la fecha
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Formatea el mes/año
      const formattedDate = `${month}/${year}`;

      return formattedDate;
    },

    formatCurrency(number) {
      // Verificar si el número es menor que 1 y ajustar el formato
      if (number < 1) {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
  },
};
</script>

<style scoped>
.my-header-style {
  background-color: #f8d07e;
}

.even-row {
  background-color: #ffedc9; /* Color para filas pares */
}

.odd-row {
  background-color: #fff; /* Color para filas impares */
}

.custom-width-class {
  width: 100%;
}

.rounded-dialogo {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 3px; /* Ajusta el relleno según sea necesario */
  color: #f8d07e;
}

.separator::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: #ccc; /* Color de separación */
  position: absolute;
  right: -1px;
  top: 0;
}
</style>

