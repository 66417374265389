<template>
  <div>
    <div>
      <!-- 
            <button class="button is-primary" @click="showModal = true">
              Mostrar Modal
            </button>
        -->

      <!-- INICIO MODAL -->

      <div class="modal" :class="{ 'is-active': showModal }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Verificación Email</p>
          </header>
          <section class="modal-card-body">
            No hemos podido validar tu dirección de correo electrónico de
            registro:
            <span class="has-text-weight-bold">{{ this.datos.email }}</span
            >. Haz clic en el botón 'Verificar Correo Electrónico' para
            intentarlo nuevamente e inicia sesión de nuevo.

            <br /><br />

            <span class="has-text-centered is-size-7 has-text-right">
              (No olvides reivisar tu bandeja de Spam).
            </span>
          </section>

          <footer class="modal-card-foot">
            <button
              class="button is-primary pa-3"
              @click="enviarDeNUevo"
              :disabled="estadoBoton"
            >
              Verificar Email
            </button>

            <span v-if="mensaje">
              {{ mensaje }}
            </span>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { veirificarEmail } from "@/config/registro/index";
export default {
  name: "DashboardVerificacion",

  props: {
    datos: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showModal: false,
      mensaje: null,
      estadoBoton: false,
    };
  },

  mounted() {
    this.showModal = !this.datos.emailVerified;
  },

  methods: {
    async enviarDeNUevo() {
      this.estadoBoton = true;
      this.mensaje = "Procesando solicitud...";

      try {
        const respuesta = await veirificarEmail();
        //console.log(respuesta);
        if (respuesta) {
          this.mensaje = "Hemos enviado a tu correo la validación";

          setTimeout(() => {
            this.estadoBoton = false;
            (this.mensaje = null), this.$router.push({ name: "Login" });
          }, 3000); // 3000 milisegundos = 3 segundos
        } else {
          this.mensaje = "Ha fallado el envio, intente de nuevo";

          setTimeout(() => {
            this.estadoBoton = false;
            (this.mensaje = null), this.$router.push({ name: "Login" });
          }, 3000); // 3000 milisegundos = 3 segundos
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
</style>