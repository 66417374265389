<template>
  <div class="column is-three-six is-offset-one-six is-mobile">
    <div class="content is-centered is-normal">
      <div class="column is-three-fifths is-offset-one-fifth is-mobile">
        <bar />
        <div class="block box rounded-box mt-3">
          <h1 class="title bienvenido-text">Bienvenido</h1>

          <!-- NOMBRES -->
          <div v-if="datosUsuarioDb != null">
            <div v-if="this.datosUsuarioDb[0].datos.nombres == null">
              <span>
                {{ this.datosUsuario.email }}
              </span>
            </div>
            <div v-else>
              <p>
                {{ this.datosUsuarioDb[0].datos.nombres }}
              </p>
            </div>
          </div>

          <!-- VALIDACIONES -->
          <div class="tags has-addons" v-if="datosUsuarioDb != null">
            <span v-if="!verificaEmail">
              <span class="tag is-danger">Pendiente por validar correo</span>
            </span>
            <span v-else>
              <span class="tag is-black">Correo Válido</span>
            </span>

            <span
              class="ml-2"
              v-if="this.datosUsuarioDb[0].datos.terycondi === false"
            >
              <span class="tag is-danger"
                >Pendiente aceptar Termino y Condiciones</span
              >
            </span>
            <span v-else>
              <span class="tag is-black ml-2">Datos Personales Válidos</span>
            </span>
          </div>

          <p class="mb-3" v-else>Cargando validación de usuario...</p>

          <!-- PANELES -->
          <div
            class="columns is-narrow-tablet mt-3"
            v-if="modulosFinales.length > 0"
          >
            <div
              class="column"
              v-for="(panel, index) in modulosFinales"
              :key="index"
            >
              <div
                class="buttonPanel box has-text-centered relative-container"
                :class="{ 'disabled-panel': !panel.estado }"
                @click="eventosPanel(panel.evento)"
              >
                <span v-if="panel.alerta" class="icon absolute-icon">
                  <i class="fas fa-bell has-text-danger"></i>
                </span>

                <span class="icon is-large" style="color: black">
                  <i :class="panel.iconoPrincipal"></i>
                </span>
                <br />
                {{ panel.tablero }}
              </div>
            </div>
          </div>

          <h4 style="color: #f8d07e;">DISCLAIMER</h4>

          <p style="color: blanchedalmond;">
            La información aquí contenida no hace parte ni pretende replicar
            prácticas de ninguna organización nacional o internacional. En
            cualquier inversión en los diferentes activos financieros existentes
            en el mundo el riesgo financiero siempre está presente.
            <br><br>
            El producto de inversión que se
            ofrece ha sido probado con diferentes estrategias, probadas y
            comprobadas que aseguran la correcta gestión y utilidades en función
            del tiempo. 
          </p>
          <!-- END PANELES -->
        </div>
      </div>
    </div>

    <!-- MODAL que permite validar correo -->
    <verifica-email
      v-if="!verificaEmail && datosUsuario !== null"
      :datos="datosUsuario"
    />

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>
    
<script>
import VerificaEmail from "@/components/Dashboard/verificaciones/Corre.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { useStore } from "vuex";
import { consultaUsuarioFB } from "@/config/perfil/index";

export default {
  name: "DashboardPrincipal",
  components: { VerificaEmail, Whatsapp, Bar },
  data() {
    return {
      rol: "inversor", //adm - inversor
      login: null,
      datosUsuario: null,
      verificaEmail: null,
      modulos: [
        {
          tablero: "Mi Perfil",
          evento: "perfil",
          alerta: false,
          estado: true,
          iconoPrincipal: "fas fa-user-tie fa-3x",
          rol: ["adm", "inversor"],
        },
        {
          tablero: "Cal. de Inversión",
          evento: "calculadora",
          alerta: false,
          estado: true,
          iconoPrincipal: "fas fa-calculator fa-3x",
          rol: ["inversor"],
        },
        {
          tablero: "Transacciones",
          evento: "transacciones",
          alerta: false,
          estado: true,
          iconoPrincipal: "fas fa-money-bill-transfer fa-3x",
          rol: ["inversor"],
        },
        {
          tablero: "Procesos",
          evento: "admin",
          alerta: false,
          estado: true,
          iconoPrincipal: "fas fa-cog fa-3x",
          rol: ["adm"],
        },
        /*{
          tablero: "Comunicados",
          evento: "comunicados",
          alerta: false,
          estado: false,
          iconoPrincipal: "fas fa-newspaper fa-3x",
          rol: ["adm", "inversor"],
        },*/
      ],
      modulosFinales: [],
      datosUsuarioDb: null,
    };
  },
  async created() {
    // Ejemplo de cómo acceder al estado del store
    await this.inicialziar();
  },

  methods: {
    async inicialziar() {
      const store = useStore();

      //console.log("Estado de isLoggedIn:", store.state.isLoggedIn);
      //console.log("Datos de usuario:", store.state.userData);

      this.login = store.state.isLoggedIn;
      this.datosUsuario = store.state.userData;
      this.verificaEmail = this.datosUsuario.emailVerified;

      // FALTA ROL por Ajustar...
      const result = await consultaUsuarioFB(this.datosUsuario.email);

      //console.log(result);
      if (result[0]) {
        this.datosUsuarioDb = result[1];
        this.rol = this.datosUsuarioDb[0].datos.rol;
        //console.log(this.datosUsuarioDb[0].id);
        //console.log(this.datosUsuarioDb[0].datos.movil);
        this.procesarPaneles();
      }

      if (!this.login) {
        this.$router.push({ name: "Login" });
      }
    },

    procesarPaneles() {
      const panelesMostrados = this.panelesMostrados;
      // Haz lo que necesites con el nuevo array panelesMostrados
      this.modulosFinales = panelesMostrados;
      //console.log(panelesMostrados);
    },

    eventosPanel(panel) {
      switch (panel) {
        case "perfil":
          this.$router.push({ name: "Perfil" });
          break;
        case "calculadora":
          this.$router.push({ name: "Calculadora" });
          break;
        case "transacciones":
          this.$router.push({ name: "Transacciones" });
          break;
        case "comunicados":
          this.$router.push({ name: "Comunicados" });
          break;
        case "admin":
          this.$router.push({ name: "Admin" });
          break;

        default:
          break;
      }
    },
  },

  computed: {
    panelesMostrados() {
      return this.modulos.filter((panel) => {
        return panel.rol.includes(this.rol);
      });
    },
  },
};
</script>


<style scoped>
/* Estilos para el botón flotante */
.floating-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
}

.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.flex-fixed-width {
  flex: 1;
  min-width: 200px; /* Puedes ajustar este valor según tus necesidades */
  margin: 0 5px; /* Espacio entre los cuadros */
}

.buttonPanel {
  cursor: pointer;
  background: #f8d07e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ajusta según tus preferencias */
  /* Otros estilos del botón, como color de texto, bordes, etc. */
  color: #222222; /* Ajusta el color del texto según sea necesario */
  border: none; /* Otras propiedades de borde según sea necesario */
  padding: 10px 20px; /* Ajusta el relleno según sea necesario */
  border-radius: 15px; /* Ajusta el radio del borde según sea necesario */
}

.relative-container {
  position: relative;
}

.absolute-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  transform: translate(50%, -50%);
}

.disabled-panel {
  pointer-events: none;
  opacity: 0.6; /* Ajusta la opacidad según tus preferencias */
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #cfcfcf; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h1.bienvenido-text {
  color: #f8d07e;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.rounded-box p,
.rounded-box span {
  color: #f8d07e;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}
</style>