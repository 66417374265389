<template>
  <v-dialog scrollable width="auto" persistent>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        size="x-small"
        icon="fas fa-user"
        style="background-color: #f8d07e"
        class="mt-2"
        @click="consultaDatos"
      >
      </v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card
        max-height="95%"
        style="background-color: #f6f6f6; border-radius: 25px"
      >
        <v-toolbar color="black" class="p-2">
          <v-row class="p-3">
            <v-col cols="12" md="12">
              <h2 class=" font-weight-bold">
                {{
                  formatName(
                    selectedItem.datos.nombres,
                    selectedItem.datos.email
                  )
                }}
              </h2>
            </v-col>
            <v-col cols="12" md="12" class="mt-n6">
              <span class="date-text mt-n5">
                Fecha Registro:
                {{ formatTimestamp(selectedItem.datos.fechaRegistro) }}
              </span>
            </v-col>
          </v-row>
        </v-toolbar>

        <v-card-text>
          <!--PARTE 1-->
          <div class="columns is-mobile is-multiline">
            <!-- Input de Capital :value="formatCurrency(totalinversion)" -->
            <div class="column is-full-mobile">
              <label>Capital</label>
              <input
                v-model="totalInversion"
                class="input"
                type="text"
                placeholder="Capital"
                @change="cambioDatoTitular('investment', totalInversion)"
                style="background-color: #ffeecc"
              />
            </div>

            <!-- Input de Rendimientos -->
            <div class="column is-full-mobile">
              <label
                >Rendimientos al
                <v-chip class="mt-n2" color="teal-darken-4" variant="outlined">
                  {{ porcentajePrincipal }}%</v-chip
                ></label
              >
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="totalRendimiento"
                  :placeholder="`Rendimientos / ${porcentajePrincipal}`"
                  @change="cambioDatoTitular('rentabilidadTotal', totalRendimiento)"
                  style="background-color: #dcffcc"
                />
              </div>
            </div>

            <!-- Input de Cuenta Destino -->
            <div class="column is-full-mobile">
              <label>Cuenta Destino</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="cuentaDestino"
                  placeholder="Cuenta destino"
                  disabled
                />
              </div>
            </div>
          </div>

          <!--PARTE 2-->
          <div class="columns is-mobile is-multiline">
            <!-- Input de Tipo de Cuenta -->
            <div class="column is-full-mobile">
              <label>Tipo de Cuenta</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="tipoCuenta"
                  placeholder="Tipo de cuenta"
                  disabled
                />
              </div>
            </div>

            <!-- Input de Entidad Bancaria -->
            <div class="column is-full-mobile">
              <label>Entidad Bancaria</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="entidadBancaria"
                  placeholder="Entidad bancaria"
                  disabled
                />
              </div>
            </div>

            <!-- Switch para activar/desactivar -->
            <div class="column is-full-mobile">
              <label>Estado Admin / Documentos</label>
              <div class="control">
                <v-row>
                  <v-col>
                    <v-switch
                      :label="activado ? 'Activo' : 'Inactivo'"
                      v-model="activado"
                      color="green"
                    ></v-switch>
                  </v-col>
                  <v-col class="mt-3">
                    <v-btn
                      :disabled="urlDoc ? false : true"
                      icon="fas fa-file"
                      size="x-small"
                      @click="openUrlInNewTab(urlDoc)"
                      style="background-color: #b8b8b8"
                    ></v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <!-- PROXIMOS PAGOS 
          <div>
            <h6 class="mb-n4">Proximos pagos</h6>
            <v-divider class="mb-n1" color="orange-darken-1"></v-divider>
          </div>-->

          <!-- TRANSACCIONES -->
          <div v-if="!procesoRegistroInversion">
            <!--
              <h6 class="mb-n4">Transacciones</h6>
              <v-divider class="mb-n1" color="orange-darken-1"></v-divider>
            -->
            <TableTran
              :juego="registros"
              v-if="registros.length > 0"
              :key="componentKey"
              :refreshData="consultaDatos"
              @cambio-estados="estadosInvHijo"
            />

            <div v-else>
              <progress
                class="progress is-small is-warning"
                max="100"
              ></progress>
            </div>
          </div>
          <div v-else>
            <div>No hay Transacciones</div>
          </div>
        </v-card-text>

        <v-card-actions class="v-toolbar">
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            block
            rounded
            variant="outlined"
            @click="isActive.value = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>


<script>
import {
  consultaUsuInvFB,
  actualizaRentabilidadMensualFB,
  actualizaInversionUsuarioBasico,
  //actualizaInvestment,
} from "@/config/inversiones/indexAd";
import TableTran from "./TablaAdTrans.vue";

export default {
  name: "tablaPrincipal",

  props: {
    selectedItem: Object, // Recibe el registro seleccionado
    refreshData: Function, // Pasa la función como propiedad
  },

  components: {
    TableTran,
  },

  data() {
    return {
      capital: "",
      rendimientos: "",
      cuentaDestino: "",
      tipoCuenta: "",
      entidadBancaria: "",
      urlDoc: "",
      activado: false,
      tablaData: [],
      totalInversion: 0,
      totalRendimiento: 0,
      porcentajePrincipal: 0,
      registros: [],
      componentKey: 0, // Inicializa la clave
      procesoRegistroInversion: false,

      dialogRefs: {},
      totalInversionUp: 0,
    };
  },

  mounted() {},

  methods: {
    async consultaDatos() {
      this.procesoRegistroInversion = true; //SPiner

      //this.totalInversion = 0;
      this.registros = [];

      //console.log(this.selectedItem.datos);

      this.entidadBancaria = this.selectedItem.datos.entidadFinanciera;
      this.tipoCuenta = this.selectedItem.datos.tipoC;
      this.cuentaDestino = this.selectedItem.datos.nCuenta;
      this.urlDoc = this.selectedItem.datos.file;

      let json = {
        idUser: this.selectedItem.datos.id,
        correoUser: this.selectedItem.datos.email,
      };

      //console.log(this.totalInversion);

      const resultUI = await consultaUsuInvFB(json);
      //console.log(resultUI);

      if (resultUI[0]) {
        this.procesoRegistroInversion = false; //Spiner

        this.porcentajePrincipal = resultUI[1][0].datos.porcentaje;

        this.registros = resultUI[1];//Trae en [0] toda la inversión inicial

        //const juegoDatos = resultUI[1][0].datos.rentabilidadMensual; //Juego de datos a analizar

        this.totalInversion = this.formatCurrency(this.registros[0].datos.investment);

        // RENDIMIENTOS
        let numeroTotal = await this.calcularRentabilidadTotal(resultUI[1]);
        this.totalRendimiento = this.formatCurrency(numeroTotal);
      } else {
        // Manejar el caso de error si es necesario
        this.procesoRegistroInversion = true; //Spiner
      }
      //console.log(this.totalInversion);
    },

    //Transacciones de Tabla Administrador
    async estadosInvHijo(datosLlegan) {
      //console.log(datosLlegan);

      //Procesando Transacciones y Estados de Admin
      const resultUpdateRM = await actualizaRentabilidadMensualFB(datosLlegan);

      //console.log(resultUpdateRM);
      if (resultUpdateRM) {
        //Refrescar los datos....
        await this.consultaDatos();
        //console.log("Se ha actualizado");
      }
    },

    calcularProyeccion() {
      const fechaHoy = new Date();

      this.registros.forEach((registro) => {
        const fechaRegistro = new Date(
          registro.datos.fechaRegistro.seconds * 1000
        );

        // Verificar que 'investment' y 'porcentaje' sean números enteros
        const investment = +registro.datos.investment;
        const porcentaje = +registro.datos.porcentaje;

        //console.log(investment);
        //console.log(porcentaje);

        // Verificar si investment y porcentaje son números válidos
        if (!isNaN(investment) && !isNaN(porcentaje)) {
          const mesesDiferencia =
            (fechaHoy.getFullYear() - fechaRegistro.getFullYear()) * 12 +
            fechaHoy.getMonth() -
            fechaRegistro.getMonth();

          const gananciaMensual = investment * (porcentaje / 100);

          registro.proyeccion = [];

          for (let i = 0; i <= mesesDiferencia; i++) {
            const fechaProyeccion = new Date(fechaRegistro);
            fechaProyeccion.setMonth(fechaRegistro.getMonth() + i);

            const ganancia = {
              mes: fechaProyeccion.toLocaleString("default", { month: "long" }),
              ganancia: gananciaMensual.toFixed(2),
            };

            registro.proyeccion.push(ganancia);
          }

          if (!registro.proyeccion || !Array.isArray(registro.proyeccion)) {
            registro.proyeccion = [];
          }
        } else {
          // En caso de que 'investment' o 'porcentaje' no sean números válidos
          /*console.error(
            "Investment o porcentaje no son números válidos:",
            registro
          );*/
        }
      });

      //console.log(this.registros);
      this.tablaData = this.registros;

      // Incrementa la clave para forzar la actualización del componente
      this.componentKey += 1;
    },

    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },

    formatCurrency(number) {
      // Verificar si el número es menor que 1 y ajustar el formato
      if (number < 1) {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },

    formatName(nombres, email) {
      if (nombres) {
        // Dividir el nombre en palabras
        const words = nombres.split(" ");

        // Capitalizar la primera letra de cada palabra y unirlas de nuevo
        const formattedName = words
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ");

        return formattedName;
      } else if (email) {
        // Si no hay nombres, pero hay un correo electrónico, mostrar el correo completo
        return email;
      }

      return ""; // En caso de que no haya nombres ni correo electrónico
    },

    formatTimestamp(timestamp) {
      // Convierte los segundos a milisegundos
      const milliseconds = timestamp.seconds * 1000;

      // Crea una nueva instancia de Date con los milisegundos
      const date = new Date(milliseconds);

      // Obtiene los componentes de la fecha
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // ¡Recuerda que los meses son indexados desde 0!
      const year = date.getFullYear().toString().padStart(4, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      // Formatea la fecha y hora
      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      return formattedDate;
    },

    async calcularRentabilidadTotal(array) {
      let totalRentabilidad = 0;

      for (const item of array) {
        if (item.datos.rentabilidadTotal) {
          totalRentabilidad += parseFloat(item.datos.rentabilidadTotal);
        }
      }

      return totalRentabilidad;
    },

    async cambioDatoTitular(clave, valor){

      let json = {
        correoUser: this.selectedItem.datos.email,
        clave: clave,
        valor: Number(valor.replace('$', '')),
      };

      //const resultCAIUB = 
      await actualizaInversionUsuarioBasico(json)

      //console.log(resultCAIUB);
      
    }
  },
};
</script>

<style scoped>
.v-toolbar {
  background: linear-gradient(to bottom, #131313, #3b3b3b);
}

.date-text {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 12px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

/* Ocultar los botones de incremento y decremento en input[type="number"] */
.numeric-input input[type="number"]::-webkit-inner-spin-button,
.numeric-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  appearance: none !important;
  display: none !important;
}

/* También puedes necesitar el siguiente CSS para algunos navegadores */
.numeric-input input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>