import { auth, db, addDoc, collection, where, query, getDocs, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from "@/utils/firebaseConfig.js";

export async function LoginFB(email, password) {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;
        //console.log("Inicio de sesión exitoso:", user);
        return [true, user];
        // Aquí puedes redirigir al usuario a su dashboard u otras acciones
    } catch (error) {

        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error en el inicio de sesión:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}


export async function RegisterFB(email, password, numeroTotal, referido) {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        let json = {
            email: email,
            //password: password,
            movil: numeroTotal,
            referido: referido,
        }

        json.fechaRegistro = new Date();
        json.estado = true;
        json.estadoAdmin = false;
        json.terycondi = false;
        json.rol = "inversor";


        await addDoc(collection(db, "in-usuarios"), json);


        //return [true, result];

        // Envía el correo de verificación
        //const resultVerification =
        await sendEmailVerification(auth.currentUser);
        //console.log(resultVerification);
        //console.log('Registro exitoso:', user);


        return [true, user];
        // Aquí puedes redirigir al usuario a su dashboard u otras acciones después del registro
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error('Error en el registro:', errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

export async function recuperarContrasenaFB(email) {
    try {

        await sendPasswordResetEmail(auth, email);

        //console.log("Correo de restablecimiento de contraseña enviado");
        return [true];


    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.error("Error de reuperar contraseña:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}

export async function recuperarContrasenaPorTelefono(phone) {
    try {
       
        let emailUser;
        const q = query(collection(db, "in-usuarios"), where("movil", "==", phone));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            emailUser = doc.data().email;
            return;
        });

        if (emailUser) {
            await sendPasswordResetEmail(auth, emailUser);
        } else {
            return [false, null];
        }

        return [true, phone, emailUser];
    } catch (error) {
        //console.error('Error en la recuperación de cuenta:', error);
        return [false, null];
    }
}

export async function veirificarEmail() {
    try {
        if (auth.currentUser) {
            await sendEmailVerification(auth.currentUser);
            //console.log("Correo de verificación enviado exitosamente.");
            return true;
        } else {
            //console.log("Usuario no autenticado.");
            return false;
        }
    } catch (error) {
        //console.error("Error al enviar el correo de verificación:", error);
        return false;
    }
}

// Supongamos que el usuario quiere cambiar la contraseña
//Pendinte import EmailAuthProvider
/*async function cambiarContrasenaNueva(email, contrasenaActual, contrasenaNueva) {
    try {
        // Crear credencial con el email y la contraseña actual
        const credencial = EmailAuthProvider.credential(email, contrasenaActual);
        
        // Reautenticar al usuario con la credencial
        await reauthenticateWithCredential(auth.currentUser, credencial);

        // Ahora que el usuario está autenticado nuevamente, cambiar la contraseña
        await updatePassword(auth.currentUser, contrasenaNueva);

        console.log("Contraseña cambiada exitosamente");
        return [true];
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error al cambiar la contraseña:", errorCode, errorMessage);
        return [false, errorCode, errorMessage];
    }
}*/