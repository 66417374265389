<template>
  <div class="column is-three-five is-offset-one-five is-mobile">
    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <bar />
      <div class="block box rounded-box mt-2">
        <h1 class="title bienvenido-text">Calculadora</h1>
        <!--
          <h3 class="subtitle">Foreign Exchange</h3>
        -->
      </div>
    </div>

    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <miga />

      <div class="block box">
        <div class="column is-mobile is-justify-content-center">
          <!-- CAPITAL -->
          <p>
            La moneda en la que se proyecta las inversiones es el Dolar
            Estadounidense (USD)
          </p>

          <div class="is-full is-three-fifths mt-2" v-show="!sendSolicitud">
            <label class="label mt-3">Capital de inversión (USD):</label>
            <!-- v-model="selectedInvestment" -->
            <input
              class="input is-warning is-normal"
              type="number"
              v-model="selectedInvestment"
              :min="investmentRanges[0].minInvestment"
              :max="investmentRanges[investmentRanges.length - 1].maxInvestment"
              step="50"
              :disabled="sendSolicitud"
              style="width: 150px"
            />
            <p v-if="selectedInvestment < 1000" class="help is-danger">
              Monto mínimo de inversión es 1.000 USD
            </p>
          </div>

          <!-- MESES -->
          <div class="is-full is-three-fifths mt-2" v-show="!sendSolicitud">
            <label class="label">Selecciona el período en meses:</label>
            <div class="select">
              <select
                v-model="selectedMonths"
                :disabled="sendSolicitud"
                style="width: 150px"
              >
                <option v-for="month in months" :key="month" :value="month">
                  {{ month }} mes{{ month > 1 ? "es" : "" }}
                </option>
              </select>
            </div>
          </div>

          <!-- PORCENTAJE -->
          <div class="is-full is-three-fifths mt-2" v-show="!sendSolicitud">
            <label class="label">Porcentaje de Rentabilidad (%):</label>
            <input
              class="input is-warning is-normal"
              type="number"
              v-model="selectedRate"
              :min="0"
              :max="100"
              step="0.01"
              :disabled="sendSolicitud"
              style="width: 150px"
              required
            />
          </div>

          <!-- BOTÓN CALCULAR -->
          <div class="is-full is-three-fifths mt-3" v-show="!sendSolicitud">
            <button
              @click="calcularInversion"
              class="button is-dark is-fullwidth"
              :disabled="sendSolicitud"
            >
              Calcular
            </button>
          </div>

          <!-- BOTÓN REINICIAR -->
          <div class="is-full is-three-fifths mt-3">
            <button
              @click="reiniciarCalculo"
              class="button is-light is-fullwidth"
              :disabled="!sendSolicitud"
            >
              Volver a Calcular
            </button>
          </div>

          <!-- TABLA -->
          <div v-if="sendSolicitud" class="mt-5">
            <div class="is-full is-three-fifths mt-2">
              <label class="label text-center">Proyección de Inversión</label>
              <div class="table-container">
                <table class="table is-bordered is-fullwidth is-hoverable">
                  <!-- CABECERA DE LA TABLA -->
                  <thead>
                    <tr class="table-header">
                      <th>Mes</th>
                      <th>Inv. Inicial</th>
                      <th>G. Mensual</th>
                      <th>G. Trimestral</th>
                    </tr>
                  </thead>
                  <!-- CUERPO DE LA TABLA -->
                  <tbody>
                    <tr
                      v-for="(item, index) in tablaDatos"
                      :key="item.mes"
                      :class="{ 'color-alternado': index % 2 === 0 }"
                    >
                      <td>{{ item.mes }}</td>
                      <td>{{ formatCurrency(item.inversionInicial) }}</td>
                      <td>{{ formatCurrency(item.gananciaMensual) }}</td>
                      <td>{{ formatCurrency(item.gananciaTrimestral) }}</td>
                    </tr>
                  </tbody>
                  <!-- PIE DE LA TABLA -->
                  <tfoot>
                    <tr>
                      <td colspan="4" class="has-text-right">
                        <strong>Total de inversión:</strong>
                        {{ formatCurrency(selectedInvestment) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4" class="has-text-right">
                        <strong>Total Ganancia:</strong>
                        {{ formatCurrency(totalMonthlyEarnings) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>

                <!-- BOTÓN SOLICITAR INVERSIÓN -->
                <div class="field">
                  <button
                    @click="goSendInversion"
                    class="button is-dark is-fullwidth"
                    :disabled="!sendSolicitud || YaTieneInversion"
                  >
                    <div v-if="YaTieneInversion">
                      Dirijase al módulo 'Transacciones'
                    </div>
                    <div v-else-if="sendSolicitud">Solicitud de Inversión</div>
                    <div v-else>Enviando solicitud...</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- END TABLA -->

          <!-- ENVIO DE DATOS -->
          <div
            v-else
            class="column is-three-fifths is-offset-one-fifth is-mobile"
          >
            <div>
              <br />
              {{ textoProceso }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <whatsapp />
  </div>
</template>

<script>
import Whatsapp from "@/components/Whatsapp.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Miga from "@/components/Dashboard/Miga.vue";
import { useStore } from "vuex";
import {
  registroInversionFB,
  consultaUsuarioInversionCalFB,
} from "@/config/inversiones/index";

export default {
  name: "DashboardCalculadora",
  components: { Whatsapp, Bar, Miga },
  data() {
    return {
      selectedMonths: 3,
      selectedInvestment: 1000.0,
      months: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ],
      investmentRanges: [
        { minInvestment: 1000, maxInvestment: 4999, rate: 0.05 },
        { minInvestment: 5000, maxInvestment: 1000000, rate: 0.07 },
      ],

      rol: "inversor", //adm - inversor
      login: null,
      datosUsuario: null,
      verificaEmail: null,

      sendSolicitud: false,
      textoProceso: "",

      selectedRate: 5,
      alternarColor: true,

      YaTieneInversion: false,
    };
  },

  created() {
    this.inicialziar();
  },

  watch: {
    selectedInvestment: function (newVal) {
      this.selectedInvestment = newVal;
    },
  },

  computed: {
    selectedRange() {
      return this.investmentRanges.find(
        (range) =>
          this.selectedInvestment >= range.minInvestment &&
          this.selectedInvestment <= range.maxInvestment
      );
    },

    totalMonthlyEarnings() {
      return this.calculateTotalMonthlyEarnings();
    },

    totalQuarterlyEarnings() {
      return this.calculateTotalQuarterlyEarnings();
    },
  },

  methods: {
    async inicialziar() {
      const store = useStore();

      //console.log("Estado de isLoggedIn:", store.state.isLoggedIn);
      //console.log("Datos de usuario:", store.state.userData);

      this.login = store.state.isLoggedIn;
      this.datosUsuario = store.state.userData;
      this.verificaEmail = this.datosUsuario.emailVerified;

      //console.log("Datos de usuario:", this.datosUsuario.email);

      // FALTA ROL por Ajustar...
      if (!this.login) {
        this.$router.push({ name: "Login" });
      } else {
        const result = await consultaUsuarioInversionCalFB(
          this.datosUsuario.email
        );

        this.YaTieneInversion = result[0];
      }
    },

    calcularInversion() {
      // Validaciones de campos, si es necesario
      if (this.selectedInvestment < 1000) {
        alert("Monto mínimo de inversión es 1.000 USD");
        return;
      }

      // Lógica de cálculos
      let inversionInicial = this.selectedInvestment;
      let totalGanancia = 0;

      // Limpiar el array antes de agregar nuevos datos
      this.tablaDatos = [];

      // Llenar el array con los datos calculados para cada mes
      for (let month = 1; month <= this.selectedMonths; month++) {
        const gananciaMensual = inversionInicial * (this.selectedRate / 100);
        const gananciaTrimestral =
          month % 3 === 0
            ? inversionInicial * ((this.selectedRate / 100) * 3)
            : 0;

        totalGanancia += gananciaMensual;

        this.tablaDatos.push({
          mes: month,
          inversionInicial: inversionInicial,
          gananciaMensual: gananciaMensual,
          gananciaTrimestral: gananciaTrimestral,
        });

        // Actualizar el valor de inversionInicial para el próximo mes
        inversionInicial += gananciaTrimestral;
      }

      // Actualizar la propiedad totalMonthlyEarnings con el valor total calculado
      this.totalMonthlyEarnings = totalGanancia;

      // Establecer sendSolicitud en true para mostrar la tabla
      this.sendSolicitud = true;

      // Invertir el valor para el próximo renderizado
      this.alternarColor = !this.alternarColor;

      // Establecer sendSolicitud en true para mostrar la tabla
      this.sendSolicitud = true;
    },

    reiniciarCalculo() {
      // Restablecer los valores predeterminados
      this.selectedMonths = 3;
      this.selectedInvestment = 1000;
      this.selectedRate = 5;

      // Limpiar la tabla
      this.tablaDatos = [];

      // Establecer sendSolicitud en falso para ocultar la tabla
      this.sendSolicitud = false;
    },

    async goSendInversion() {
      try {
        this.sendSolicitud = false;
        this.textoProceso = "Estamos enviando tu solicitud";

        let json = {
          months: this.selectedMonths,
          investment: 0,
          investmentProv: this.selectedInvestment,
          usuario: this.datosUsuario.email,
          porcentaje: this.selectedRate,
          gM: this.totalMonthlyEarnings,
          gT: this.totalQuarterlyEarnings,
          fuente: "Inversión",
          descripcion: "Calculadora",
        };

        const result = await registroInversionFB(json);

        if (result[0]) {
          this.textoProceso =
            "Se ha registrado exitosamente, el administrador estará en contacto, espere por favor, lo estamos redireccionando.";

          setTimeout(() => {
            this.sendSolicitud = false;
            this.textoProceso = "";
            this.$router.push({ name: "Transacciones" });
          }, 2000);
        } else {
          this.textoProceso = "Hubo un error 200, contacte al Administrador";

          setTimeout(() => {
            this.sendSolicitud = false;
            this.textoProceso = "";
            this.$router.push({ name: "Dashboard" });
          }, 2000);
        }
      } catch (error) {
        console.error("Error en el envío de la solicitud", error);
        this.textoProceso = "Hubo un error 201, contacte al Administrador";
        this.sendSolicitud = false;
      }
    },

    calculateTotalMonthlyEarnings() {
      let total = 0;
      for (let month = 1; month <= this.selectedMonths; month++) {
        total += this.getMonthlyEarnings(month);
      }
      return total;
    },

    calculateTotalQuarterlyEarnings() {
      let total = 0;
      for (let month = 1; month <= this.selectedMonths; month++) {
        total += this.getQuarterlyEarnings(month);
      }
      return total;
    },

    getTrimestreClass(month) {
      //console.log(month);
      if (month % 3 === 1) {
        return "trimestre-1";
      } else if (month % 3 === 2) {
        return "trimestre-2";
      } else {
        return "trimestre-3";
      }
    },

    updateInvestment() {
      // No se requiere código adicional aquí, simplemente actualiza el valor del input.
      if (this.selectedInvestment < 1000) {
        this.selectedInvestment = 1000;
      } else if (this.selectedInvestment > 1000000) {
        this.selectedInvestment = 1000000;
      }
    },

    getInitialInvestment(month) {
      if (month === 1) {
        return this.selectedInvestment;
      } else {
        return (
          this.getQuarterlyEarnings(month - 1) +
          this.getInitialInvestment(month - 1)
        );
      }
    },

    getMonthlyEarnings(month) {
      return this.getInitialInvestment(month) * (this.selectedRate / 100);
    },

    getQuarterlyEarnings(month) {
      if (month % 3 === 0) {
        return (
          this.getInitialInvestment(month) * ((this.selectedRate / 100) * 3)
        );
      } else {
        return 0;
      }
    },

    formatCurrency(number) {
      // Verificar si el número es menor que 1 y ajustar el formato
      if (number < 1) {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },

    handleRateInput() {
      // Convertir la entrada del usuario en un número
      this.selectedRate = parseFloat(this.selectedRate);
      // Verificar si el campo está vacío o tiene un valor menor que 1
      if (isNaN(this.selectedRate) || this.selectedRate < 0) {
        // Establecer un valor mínimo de 0 si está vacío o menor que 0
        this.selectedRate = 0;
      }
    },

    handleRateBlur() {
      // Convertir la entrada del usuario en un número
      this.selectedRate = parseFloat(this.selectedRate);
      // Verificar si el campo está vacío o tiene un valor menor que 1
      if (isNaN(this.selectedRate) || this.selectedRate < 0) {
        // Establecer un valor mínimo de 0 si está vacío o menor que 0
        this.selectedRate = 0;
      }
    },
  },
};
</script>





<style  scoped>
.trimestre-1 {
  background-color: #f5f5f5; /* Color para el primer trimestre */
}

.trimestre-2 {
  background-color: #eaf7ea; /* Color para el segundo trimestre */
}

.trimestre-3 {
  background-color: #f0e6ff; /* Color para el tercer trimestre */
}

.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

/* Estilo para el encabezado de la tabla */
.table-header {
  background: linear-gradient(to bottom, #fff4e0, #ffdfa4);
  color: #ffffff; /* Color blanco para el texto */
  text-decoration-color: #cfcfcf;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 0px; /* 10px Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #cfcfcf; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h1.bienvenido-text {
  color: #f8d07e;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.rounded-box p,
.rounded-box span {
  color: #f8d07e;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.color-alternado {
  background-color: #fff4e0; /* Color alternado que has estado utilizando */
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>