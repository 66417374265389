<template>
  <v-container class="mt-10">
    <CabezoteA
      h2="Registro"
      title="Verifique todos los campos antes de enviar el formulario por favor."
    />

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8" lg="6">
        <!-- FORMULARIO DE REGISTRO -->
        <v-form @submit.prevent="register">
          <v-card elevation="2">
            <v-card-title>
              <h3 class="title">Registro</h3>
            </v-card-title>

            <v-card-text>
              <!-- Email -->
              <v-text-field
                v-model="email"
                label="Email"
                type="email"
                outlined
                required
                :disabled="estadoBoton"
              ></v-text-field>

              <!-- Contraseña -->
              <v-text-field
                v-model="password"
                label="Contraseña"
                :type="showPassword ? 'text' : 'password'"
                outlined
                required
                :disabled="estadoBoton"
                append-icon="fa fa-eye"
                @click:append="togglePasswordVisibility('password')"
              ></v-text-field>

              <!-- Confirmar Contraseña -->
              <v-text-field
                v-model="passwordConfirmation"
                label="Confirmar Contraseña"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                outlined
                required
                :disabled="estadoBoton"
                append-icon="fa fa-eye"
                @click:append="togglePasswordVisibility('passwordConfirmation')"
              ></v-text-field>

              <!-- Número Celular -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="selectedCountry"
                    :items="countries"
                    label="País"
                    small-chips
                    item-title="nombre"
                    item-value="indicativo"
                    outlined
                    :disabled="estadoBoton"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="phone"
                    label="Número Celular"
                    outlined
                    required
                    inputmode="numeric"
                    pattern="[0-9]*"
                    onwheel="return false;"
                    :disabled="estadoBoton"
                    @input="limitarLongitud"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="mt-n5">
                  <v-text-field
                    v-model="referido"
                    label="Referido"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- RESPUESTA -->
            <v-card-actions v-if="respuesta">
              <v-progress-linear
                class="mt-2"
                indeterminate
                color="warning"
              ></v-progress-linear>
              <span>{{ respuesta }}</span>
            </v-card-actions>

            <!-- BOTÓN REGISTRARME -->
            <v-card-actions>
              <v-btn
                color="warning"
                :disabled="
                  !(
                    selectedCountry !== '' &&
                    phone >= 9 &&
                    passwordConfirmation === password &&
                    password !== '' &&
                    passwordConfirmation !== '' &&
                    referido !== ''
                  ) || estadoBoton
                "
                @click="register"
                block
              >
                Registrarme
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- BOTONERA -->
          <v-row class="buttons mt-5">
            <v-col>
              <v-btn color="warning" outlined @click="goToLogin" block>
                INICIO
              </v-btn>
            </v-col>
          </v-row>
          <!-- FIN BOTONERA -->
        </v-form>
      </v-col>
    </v-row>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </v-container>
</template>



<script >
//import CapC from "@/components/ReCap.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { RegisterFB } from "@/config/registro";
import CabezoteA from "@/views/CabezoteA.vue";

export default {
  name: "RegisterFrom",
  //CapC
  components: { Whatsapp, CabezoteA },

  data() {
    return {
      respuesta: null,
      email: "",
      password: "",
      passwordConfirmation: "",
      passwordWithIcon: "",
      showPassword: false,
      selectedCountry: "1",
      countries: [
        {
          nombre: "Estados Unidos",
          indicativo: "1",
          bandera: "🇺🇸",
          select: true,
        },
        { nombre: "Colombia", indicativo: "57", bandera: "🇨🇴", select: false },
      ],
      estadoBoton: false,
      showPasswordConfirmation: false,
      phone: "",
      referido: "",
    };
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async register() {
      this.estadoBoton = true;
      this.respuesta = "Procesando solicitud...";
      // Validar si las contraseñas coinciden antes de registrar
      if (this.password !== this.passwordConfirmation) {
        //console.log("Las contraseñas no coinciden");
        return;
      }

      // selectedCountry
      // Extraer el número del país seleccionado
      //const numeroPais = this.selectedCountry.match(/\+(\d+)/)[1];
      //console.log("Número del país seleccionado:", this.selectedCountry);

      // celular

      //const numeroTotal = numeroPais + this.celular;

      const numeroTotal = this.selectedCountry + this.phone;

      //console.log(numeroTotal);

      const resultLogin = await RegisterFB(
        this.email,
        this.password,
        numeroTotal,
        this.referido
      );

      //console.log(resultLogin);

      if (resultLogin[0]) {
        this.respuesta =
          "Registro exitoso, por favor ve a tu correo y confirma tu registro para continuar.";
        setTimeout(() => {
          this.estadoBoton = false;
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.passwordConfirmation = "";
          this.phone = "";
          this.selectedCountry = "1";

          this.$router.push({ name: "Login" });
        }, 3500); // 3000 milisegundos = 3 segundos
      } else {
        if (resultLogin[1] == "auth/email-already-in-use") {
          this.respuesta = "El correo ya está registrado, intente de nuevo.";
        } else {
          this.respuesta = "Los datos no son correctos, intente de nuevo.";
        }

        setTimeout(() => {
          this.estadoBoton = false;
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.passwordConfirmation = "";
          this.phone = "";
          this.selectedCountry = "1";
          this.referido = "";
        }, 3000); // 3000 milisegundos = 3 segundos
      }

      //this.respuesta = null;
    },

    togglePasswordVisibility(target) {
      if (target === "password") {
        this.showPassword = !this.showPassword;
      } else if (target === "passwordConfirmation") {
        this.showPasswordConfirmation = !this.showPasswordConfirmation;
      }
    },

    togglePasswordConfirmationVisibility() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation;
    },

    limitarLongitud() {
      if (this.phone.length > 9) {
        this.phone = this.phone.slice(0, 10);
      }
    },
  },

  computed: {
    passwordMismatch() {
      return this.password !== this.passwordConfirmation;
    },

    passwordRulesError() {
      if (this.password == "") {
        return;
      }

      const uppercaseRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      return (
        this.password.length < 6 ||
        !uppercaseRegex.test(this.password) ||
        !numberRegex.test(this.password)
      );
    },
  },
};
</script>

<style scoped>
/* Estilo para ocultar las flechas en los campos numéricos */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* END Estilo para ocultar las flechas en los campos numéricos */
.select-country {
  width: 90px; /* Ajusta el ancho según tu preferencia */
}
/* Estilos para la respuesta */
.respuesta {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: hsl(171, 100%, 41%); /* Cambia el color a tu preferencia */
}

.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.control.has-icons-right .icon.is-right {
  z-index: 2;
  pointer-events: all;
  top: -10%;
}

.control input.input {
  z-index: 1;
  position: relative;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style> 