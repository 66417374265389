<template>
  <div class="column centered-content">
    <div class="logo-container">
      
      
      <img :src="require('@/assets/BBG-inversiones-500x341.png')" alt="Logo." class="logo-image" />


      <!--
      <div class="text-container">
       
        <h3 class="title">{{ h2 }}</h3>
        <h6 class="subtitle">
            {{ title }}
        </h6>
        
      </div>
       -->
    </div>
  </div>
</template>

<script>
import logoImage from '@/assets/BBG-inversiones-500x341.jpg';

export default {
  name: "CabezoteA",
  props: ["h2", "title"],
  data() {
    return {
      logoImage: logoImage,
    };
  },
};
</script>


<style>
.logo-container {
  display: flex;
  align-items: center; /* Centra verticalmente */
  justify-content: center; /* Centra horizontalmente */
}

/* Tamaño por defecto */
.logo-image {
  width: 10vw;
  height: auto;
}

/* Ajuste para dispositivos móviles */
@media screen and (max-width: 768px) {
  .logo-image {
    width: 30vw; /* Ajusta el ancho según tus preferencias */
  }
}
</style>