<template>
  <v-app>
    <v-main class="fondo-bg">
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>


<style scoped>
.fondo-bg {
  background: linear-gradient(to bottom, rgb(255, 102, 0), rgb(239, 108, 0), rgb(255, 152, 49));
  min-height: 100vh;
  height: auto;
  margin: 0;
}
</style>

<!--
firebase deploy --only hosting:inversiones-bbg
  background: {
    image: 'url(' + require('@/assets/fondo-02.png') + ')',
    size: 'cover',
    position: 'center',
  };
  min-height: '100vh';
  height: 'auto';
  margin: 0;

-->