<template>
  <div class="column is-three-five is-offset-one-five is-mobile">
    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <bar />
      <div class="block box rounded-box mt-2">
        <h1 class="title bienvenido-text">Mi Perfil</h1>
      </div>
    </div>

    <div class="column is-three-fifths is-offset-one-fifth is-mobile">
      <miga />

      <!-- Pestañas -->
      <div class="tabs is-toggle is-toggle-rounded is-centered">
        <ul>
          <li :class="{ 'is-active': activeTab === 'perfil' }">
            <a
              @click="changeTab('perfil')"
              style="background-color: #f8d07e; color: rgb(40, 40, 40)"
            >
              <span class="icon is-small">
                <i class="fas fa-user" aria-hidden="true"></i>
              </span>
              <span>Perfil</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 'contrasena' }">
            <a
              @click="changeTab('contrasena')"
              style="background-color: #f8d07e; color: rgb(40, 40, 40)"
            >
              <span class="icon is-small"
                ><i class="fas fa-lock" aria-hidden="true"></i
              ></span>
              <span>Cambio de Contraseña</span>
            </a>
          </li>
        </ul>
      </div>

      <!-- Contenido de las pestañas -->
      <div v-show="activeTab === 'perfil'" class="block box">
        <div class="column is-mobile is-flex is-justify-content-center">
          <div class="avatar-container">
            <!-- Foto de perfil -->
            <img
              @click="activarSelectorDeImagen"
              v-if="
                datosUsuarioDb &&
                datosUsuarioDb.length > 0 &&
                datosUsuarioDb[0].datos &&
                datosUsuarioDb[0].datos.avatar
              "
              :src="datosUsuarioDb[0].datos.avatar"
              alt="Avatar"
              class="avatar"
            />

            <!-- Placeholder de foto de perfil con marca de agua -->
            <div v-else class="avatar-placeholder">
              <div
                @click="activarSelectorDeImagen"
                class="watermark custom-file-upload"
              >
                <i class="fas fa-user"></i>
                <span>Foto</span>
              </div>
            </div>

            <!-- Input file para imágenes -->
            <input
              type="file"
              accept="image/jpeg, image/png"
              ref="imagenInput"
              style="display: none"
              @change="cargarImagen"
            />
          </div>
        </div>

        <!-- Contenido de la pestaña de perfil -->
        <div style="margin-top: -1rem">
          <div class="column is-mobile">
            <h2 class="label">Datos de Registro</h2>
            <form v-if="datosUsuarioDb != null" @submit.prevent="procesar">
              <div class="field">
                <label class="label">Email</label>
                <div class="control">
                  <input
                    v-model="datosUsuarioDb[0].datos.email"
                    class="input"
                    type="email"
                    placeholder="Email"
                    required
                    :disabled="true"
                  />
                </div>
              </div>

              <!-- NOMBRE COMPLETO -->
              <div class="field">
                <label class="label">Nombres completos</label>
                <div class="control">
                  <input
                    v-model="datosUsuarioDb[0].datos.nombres"
                    class="input"
                    type="text"
                    placeholder="Nombres..."
                    required
                    :disabled="respuesta != null"
                  />
                </div>
              </div>

              <!-- NUMERO CELULAR-->
              <div class="field">
                <label class="label">Número Celular</label>
                <div class="field has-addons">
                  <!-- Campo de selección de país -->
                  <div class="control has-icons-left">
                    <div class="select">
                      <select v-model="computedCountryCode">
                        <option
                          v-for="country in countries"
                          :key="country.indicativo"
                          :value="country.indicativo"
                          :disabled="respuesta != null"
                        >
                          {{ country.bandera }} (+{{ country.indicativo }})
                        </option>
                      </select>
                    </div>
                    <div class="icon is-small is-left">
                      <i class="fas fa-globe"></i>
                    </div>
                  </div>

                  <!-- Campo de número de celular -->

                  <div class="control is-expanded">
                    <div class="control">
                      <input
                        class="input"
                        type="number"
                        placeholder="Número Celular"
                        v-model="computedCellNumber"
                        required
                        :disabled="respuesta != null"
                        onwheel="return false;"
                        @input="limitarLongitud"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Divisor -->
              <hr class="divider" />

              <!-- TIPO CUENTA-->
              <div class="field">
                <label class="label">Tipo de Cuenta</label>
                <div class="field is-grouped">
                  <div class="control is-expanded-mobile">
                    <div class="control">
                      <label class="radio">
                        <input
                          type="radio"
                          name="tipoCuenta"
                          value="ahorros"
                          :checked="
                            datosUsuarioDb[0].datos &&
                            datosUsuarioDb[0].datos.tipoC === 'ahorros'
                          "
                          @change="updateTipoCuenta('ahorros')"
                          required
                        />
                        Ahorros
                      </label>
                      <label class="radio">
                        <input
                          type="radio"
                          name="tipoCuenta"
                          value="corriente"
                          :checked="
                            datosUsuarioDb[0].datos &&
                            datosUsuarioDb[0].datos.tipoC === 'corriente'
                          "
                          @change="updateTipoCuenta('corriente')"
                          required
                        />
                        Corriente
                      </label>
                      <label class="radio">
                        <input
                          type="radio"
                          name="tipoCuenta"
                          value="Wallet"
                          :checked="
                            datosUsuarioDb[0].datos &&
                            datosUsuarioDb[0].datos.tipoC === 'wallet'
                          "
                          @change="updateTipoCuenta('wallet')"
                          required
                        />
                        Wallet
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- TOPO DE CUENTA  -->
              <div class="field">
                <label class="label">{{ tituloCuenta }}</label>
                <div class="control">
                  <input
                    :value="datosUsuarioDb[0].datos.nCuenta"
                    @input="updateIdentidadFinanciera"
                    class="input"
                    type="text"
                    :placeholder="tituloCuenta"
                    required
                    :disabled="respuesta != null"
                  />
                </div>
              </div>

              <!-- ENTIDAD FINANCIERA  -->
              <div class="field">
                <label class="label">Entidad Financiera</label>
                <div class="control">
                  <input
                    v-model="datosUsuarioDb[0].datos.entidadFinanciera"
                    class="input"
                    type="text"
                    placeholder="Entidad Financiera..."
                    required
                    :disabled="respuesta != null"
                  />
                </div>
              </div>

              <!-- FILE CUENTA -->
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field">
                    <label class="label"
                      >Contrato y Documentos Adicionales</label
                    >
                    <p>
                      Por favor, cargue el contrato firmado y una copia de su
                      documento de identidad en un solo archivo y en formato
                      PDF. Tenga en cuenta que cada vez que cargue un nuevo
                      archivo, el archivo anterior se sobrescribirá.
                    </p>
                    <div class="file is-fullwidth has-name">
                      <label class="file-label">
                        <input
                          class="file-input"
                          type="file"
                          name="resume"
                          ref="fileInput"
                          @change="handleFileChange"
                          :disabled="respuesta != null"
                          accept="application/pdf"
                        />
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">Subir</span>
                        </span>
                        <span class="file-name">{{ selectedFileName }}</span>
                      </label>
                      <div class="file-button-container">
                        <button
                          class="button is-dark"
                          type="button"
                          :disabled="
                            datosUsuarioDb[0].datos.file == null ||
                            datosUsuarioDb[0].datos.file == undefined
                          "
                          @click="openLink(datosUsuarioDb[0].datos.file)"
                        >
                          <span class="file-icon">
                            <i class="fas fa-image"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ACEPTO TERMINOS Y CONDICIONES -->
              <label class="checkbox mt-5">
                <input
                  type="checkbox"
                  v-model="tyc"
                  :disabled="
                    respuesta != null ||
                    this.datosUsuarioDb[0].datos.terycondi != false
                  "
                  required
                />
                Acepto terminos y condiciones
              </label>

              <div class="buttons mt-5">
                <button
                  class="button is-dark"
                  :disabled="
                    respuesta != null ||
                    datosUsuarioDb[0].datos.nombres == '' ||
                    this.datosUsuarioDb[0].datos.movil.length < 10 ||
                    this.datosUsuarioDb[0].datos.numeroC == ''
                  "
                >
                  Actualizar Datos
                </button>
              </div>

              <!-- RESPUESTA -->
              <span v-if="respuesta" class="respuesta">
                <progress
                  class="progress is-small is-warning"
                  max="100"
                ></progress>
                {{ respuesta }}
              </span>
            </form>
            <div v-else>
              <progress
                class="progress is-small is-warning"
                max="100"
              ></progress>
              Cargando Perfil
            </div>
          </div>
        </div>
      </div>

      <!-- CAMBIO DE CONTRASEÑA -->
      <div v-show="activeTab === 'contrasena'" class="block box">
        <!-- Contenido de la pestaña de cambio de contraseña -->

        <!-- CAMBIO PASSWORD-->

        <div class="mt-5">
          <label class="label">Cambio de Contraseña</label>

          <div v-if="password1.length < 6">
            La contraseña debe tener al menos 6 caracteres.
          </div>
          <div v-if="!hasNumber">
            La contraseña debe contener al menos un número.
          </div>
          <div v-if="!hasUppercase">
            La contraseña debe contener al menos una letra mayúscula.
          </div>

          <div class="columns" style="margin-top: 10px">
            <div class="column is-full">
              <!-- PASSWORD 1-->
              <div class="field">
                <label class="label">Password Actual</label>
                <div class="control is-expanded position-relative">
                  <input
                    class="input"
                    type="password"
                    placeholder="********"
                    v-model="passAct"
                    required
                  />
                </div>
              </div>

              <!-- PASSWORD INICIAL-->
              <div class="field">
                <label class="label">Nuevo Password</label>
                <div class="control is-expanded position-relative">
                  <input
                    class="input"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="********"
                    v-model="password1"
                    required
                  />
                  <span
                    class="icon is-right clickable-icon"
                    @click="togglePassword"
                  >
                    <i
                      :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    ></i>
                  </span>
                </div>
              </div>
              <!-- PASSWORD 2-->
              <div class="field">
                <label class="label">Confirmar nuevo Password</label>
                <div class="control is-expanded">
                  <input
                    class="input"
                    :type="showPassword2 ? 'text' : 'password'"
                    placeholder="********"
                    v-model="password2"
                    required
                  />
                  <span
                    class="icon is-right clickable-icon"
                    @click="togglePassword2"
                  >
                    <i
                      :class="showPassword2 ? 'fas fa-eye-slash' : 'fas fa-eye'"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="buttons mt-5">
            <button
              @click="checkAndChangePassword"
              class="button is-dark"
              :disabled="estadoBoton"
            >
              Cambiar Contraseña
            </button>
          </div>
        </div>

        <!-- END PASSWORD -->
      </div>

      <!-- Divisor
      <hr class="is-divider" /> -->
    </div>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";

import Whatsapp from "@/components/Whatsapp.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Miga from "@/components/Dashboard/Miga.vue";
import { useStore } from "vuex";
import {
  consultaUsuarioFB,
  updateUsuarioFB,
  updateUsuarioPass,
  saveImageToFolder,
  getImageFromFolder,
} from "@/config/perfil/index";

export default {
  name: "DashboardPerfil",
  components: { Whatsapp, Bar, Miga },
  data() {
    return {
      respuesta: null,
      rol: "inversor", //adm - inversor
      login: null,
      datosUsuario: null,
      verificaEmail: null,
      password: null,
      sendSolicitud: false,
      textoProceso: "",
      cellNumber: "",
      tyc: true,
      tipoCuenta: "",

      selectedCountry: "1",
      countries: [
        {
          nombre: "Estados Unidos",
          indicativo: "1",
          bandera: "🇺🇸",
          select: true,
        },
        { nombre: "Colombia", indicativo: "57", bandera: "🇨🇴", select: false },
      ],

      datosUsuarioDb: null,
      estadoBoton: true,
      selectedFile: null,

      password1: "",
      password2: "",
      showPassword: false,

      showPassword2: false,
      passAct: "",
      activeTab: "perfil", // Pestaña activa por defecto
    };
  },

  created() {
    this.inicialziar();
  },

  computed: {
    tituloCuenta() {
      const tipoCuenta = this.datosUsuarioDb[0].datos.tipoC;

      if (tipoCuenta === "ahorros" || tipoCuenta === "corriente") {
        return "Número de cuenta";
      } else if (tipoCuenta === "wallet") {
        return "Usuario / e-mail";
      }

      // Puedes agregar más casos según sea necesario
      return "Esperando Tipo de Cuenta";
    },

    selectedFileName() {
      return this.selectedFile ? this.selectedFile.name : "";
    },

    computedCountryCode: {
      get() {
        if (!this.datosUsuarioDb[0].datos.movil) return "";

        for (let country of this.countries) {
          if (
            this.datosUsuarioDb[0].datos.movil.startsWith(country.indicativo)
          ) {
            return country.indicativo;
          }
        }
        return "";
      },
      set(newValue) {
        if (newValue !== this.computedCountryCode) {
          this.datosUsuarioDb[0].datos.movil =
            newValue + this.computedCellNumber;
        }
      },
    },

    computedCellNumber: {
      get() {
        if (!this.datosUsuarioDb[0].datos.movil || !this.computedCountryCode)
          return "";

        return this.datosUsuarioDb[0].datos.movil.slice(
          this.computedCountryCode.length
        );
      },
      set(newValue) {
        if (newValue !== this.computedCellNumber) {
          this.datosUsuarioDb[0].datos.movil =
            this.computedCountryCode + newValue;
        }
      },
    },

    hasNumber() {
      return /\d/.test(this.password1);
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password1);
    },
  },

  methods: {
    async inicialziar() {
      const store = useStore();

      //console.log("Estado de isLoggedIn:", store.state.isLoggedIn);
      //console.log("Datos de usuario:", store.state.userData);

      this.login = store.state.isLoggedIn;
      this.datosUsuario = store.state.userData;
      this.verificaEmail = this.datosUsuario.emailVerified;

      //console.log("Datos de usuario:", this.datosUsuario.email);

      // FALTA ROL por Ajustar...
      if (!this.login) {
        this.$router.push({ name: "Login" });
      }

      const result = await consultaUsuarioFB(this.datosUsuario.email);

      if (result[0]) {
        this.datosUsuarioDb = result[1];
        //console.log(this.datosUsuarioDb[0].id);
        //console.log(this.datosUsuarioDb[0].datos.movil);
        
      }

      const imagenLlega = await getImageFromFolder(this.datosUsuarioDb[0].id);
      this.datosUsuarioDb[0].datos.avatar = imagenLlega;
        
    },

    activarSelectorDeImagen() {
      // Activar manualmente el input file
      this.$refs.imagenInput.click();
    },

    cargarImagen(event) {
      // Manejar la carga de la imagen aquí
      // Acceder al archivo seleccionado con event.target.files[0]
      const imagenFile = event.target.files[0];

      // Verificar si se seleccionó un archivo y es una imagen
      if (imagenFile && imagenFile.type.startsWith("image/")) {
        // Crear un objeto URL para la vista previa de la imagen
        const imagenURL = URL.createObjectURL(imagenFile);

        // Llamar a la función para guardar la imagen en Firebase Storage
        saveImageToFolder(this.datosUsuarioDb[0].id, imagenFile)
          .then((downloadURL) => {
            // Asignar la URL de descarga al atributo src de la imagen
            this.datosUsuarioDb[0].datos.avatar = downloadURL;
          })
          .catch((error) => {
            console.error(
              "Error al guardar la imagen en Firebase Storage:",
              error
            );
          });

        // Asignar la URL de la vista previa al atributo src de la imagen
        this.datosUsuarioDb[0].datos.avatar = imagenURL;
      }
    },

    changeTab(tab) {
      this.activeTab = tab;
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    updateTipoCuenta(tipoSeleccionado) {
      if (this.datosUsuarioDb[0].datos) {
        this.datosUsuarioDb[0].datos.tipoC = tipoSeleccionado;
      } else {
        // Si datosUsuarioDb[0].datos no existe, crea un objeto con la propiedad tipoC
        this.datosUsuarioDb[0].datos = { tipoC: tipoSeleccionado };
      }
    },

    updateIdentidadFinanciera(event) {
      if (!this.datosUsuarioDb[0].datos) {
        this.datosUsuarioDb[0].datos = {}; // Crear el objeto datos si no existe
      }
      this.datosUsuarioDb[0].datos.nCuenta = event.target.value;
    },

    async procesar() {
      this.respuesta = "Procesando actualización...";

      //console.log(this.datosUsuarioDb[0]);

      let json = {
        id: this.datosUsuarioDb[0].id,
        nombres: this.datosUsuarioDb[0].datos.nombres,
        movil: this.datosUsuarioDb[0].datos.movil,

        tipoC: this.datosUsuarioDb[0].datos.tipoC, //ok
        nCuenta: this.datosUsuarioDb[0].datos.nCuenta,
        entidadFinanciera: this.datosUsuarioDb[0].datos.entidadFinanciera,
        file: this.selectedFile,
        terycondi: this.tyc,
      };

      const resultUpdate = await updateUsuarioFB(json);

      if (resultUpdate[0]) {
        this.respuesta = "Se ha actualizado con exito";

        setTimeout(() => {
          //this.datosUsuarioDb = null;
          //this.respuesta = null;
          //this.inicialziar();
          this.$router.push({ name: "Dashboard" });
        }, 3000); // 3000 milisegundos = 3 segundos
      } else {
        this.respuesta = "Hubo una falla intente luego";

        setTimeout(() => {
          this.respuesta = null;
        }, 3000); // 3000 milisegundos = 3 segundos
      }
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    validatePassword() {
      const pattern = /^(?=.*[0-9])(?=.*[A-Z]).{6,}$/;
      if (this.password1 === this.password2 && pattern.test(this.password1)) {
        this.estadoBoton = false;
      } else {
        this.estadoBoton = true;
      }
    },

    checkAndChangePassword() {
      //if (!this.estadoBoton) {
      this.cambiarPassword();
      //}
    },

    async cambiarPassword() {
      if (this.password1 == "" && this.password2 == "" && this.passAct == "") {
        return;
      }

      const jsonPass = {
        actual: this.passAct,
        cambio: this.password1,
        email: this.datosUsuario.email,
      };

      const resultPass = await updateUsuarioPass(jsonPass);

      if (resultPass) {
        this.respuesta = "Se ha modificado su contraseña, de manera exitosa.";

        setTimeout(async () => {
          await this.cerrarSesion();
          this.respuesta = null;
          this.$router.push({ name: "Login" });
        }, 3000);
      } else {
        this.respuesta = "Ha habido un error, intente de nuevo.";
        setTimeout(async () => {
          await this.cerrarSesion();
          this.respuesta = null;
          this.$router.push({ name: "Login" });
        }, 3000);
      }
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },

    togglePassword2() {
      this.showPassword2 = !this.showPassword2;
    },

    async cerrarSesion() {
      const auth = getAuth();
      try {
        await signOut(auth);
        //console.log("Sesión cerrada exitosamente");
      } catch (error) {
        console.error(error);
      }
    },

    limitarLongitud() {
      //console.log(this.computedCellNumber);
      if (this.datosUsuarioDb[0].datos.movil.length > 9) {
        this.computedCellNumber = this.computedCellNumber.slice(0, 10);
      }
      //console.log(this.datosUsuarioDb[0].datos.movil);
    },
  },

  watch: {
    password1(val) {
      this.validatePassword(val);
    },
    password2(val) {
      this.validatePassword(val);
    },
  },
};
</script>

<style  scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

/* Personaliza el estilo del divisor según tus preferencias */
.is-divider {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

@media screen and (max-width: 768px) {
  .is-expanded-mobile {
    width: 90% !important;
  }
}

.clickable-icon {
  cursor: pointer;
  position: absolute;
  right: 5px; /* Ajusta según necesites. Este valor determina la distancia desde el borde derecho del input. */
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999 !important; /* Aumenta el z-index para asegurar que esté en la capa superior. */
}

.file-button-container {
  display: flex;
  align-items: center;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 0px; /* 10px Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #cfcfcf; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h1.bienvenido-text {
  color: #f8d07e;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

.rounded-box p,
.rounded-box span {
  color: #f8d07e;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* Otros estilos según sea necesario */
}

/* Estilo para el divisor */
.divider {
  border: none;
  height: 1px; /* Grosor del divisor */
  background-color: #f8d07e; /* Color deseado */
  margin: 20px 0; /* Espaciado superior e inferior del divisor */
}

.tabs li.is-active a.custom-tab {
  background-color: #f8d07e;
}

/* Estilos de ejemplo, ajusta según tus necesidades */
.avatar-container {
  position: relative;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
}

.avatar-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-avatar-button {
  margin-top: 10px;
  cursor: pointer;
}

.watermark {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.watermark i {
  margin-bottom: 4px; /* Ajusta según sea necesario */
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 20px; /* Ajusta el radio según tus preferencias */
  overflow: hidden; /* Esto asegura que los bordes redondeados se mantengan */
}

.custom-file-upload span {
  margin-left: 10px;
}
</style>