<template>
  <div class="column is-three-five is-offset-one-five is-mobile">
    <bar />

    <div class="content is-centered is-normal box">
      <div class="column is-three-fifths is-offset-one-fifth is-mobile">
        <div class="block">
          <h1 class="title">Comunicados</h1>
          <h3 class="subtitle">Foreign Exchange</h3>
        </div>
      </div>

      <miga></miga>

      <!-- CAPITAL -->
      <div class="field">
        <div class="column is-three-fifths is-offset-one-fifth is-mobile">
          <label class="label">Listado</label>
        </div>
      </div>
    </div>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </div>
</template>

<script>
import Whatsapp from "@/components/Whatsapp.vue";
import Bar from "@/components/Dashboard/bar_01.vue";
import Miga from "@/components/Dashboard/Miga.vue";
import { useStore } from "vuex";
export default {
  name: "DashboardNoticias",
  components: { Whatsapp, Bar, Miga },
  data() {
    return {
      rol: "inversor", //adm - inversor
      login: null,
      datosUsuario: null,
      verificaEmail: null,

      sendSolicitud: false,
      textoProceso: "",
    };
  },

  created() {
    this.inicialziar();
  },

  methods: {
    inicialziar() {
      const store = useStore();

      console.log("Estado de isLoggedIn:", store.state.isLoggedIn);
      //console.log("Datos de usuario:", store.state.userData);

      this.login = store.state.isLoggedIn;
      this.datosUsuario = store.state.userData;
      this.verificaEmail = this.datosUsuario.emailVerified;

      //console.log("Datos de usuario:", this.datosUsuario.email);

      // FALTA ROL por Ajustar...
      if (!this.login) {
        //this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>

<style  scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
</style>