<template>
  <v-container class="mt-10">
    <CabezoteA h2="Recuperar Cuenta" title="Inversiones Beraca BG" />

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8" lg="6">
        <!-- FORMULARIO DE RECUPERACIÓN DE CUENTA -->
        <v-form @submit.prevent="recuperarCuenta">

          <v-card elevation="2">
            <v-card-title>
              <h3 class="title">Recuperar Cuenta</h3>
              <h6 class="subtitle">Digite su número de teléfono registrado</h6>
            </v-card-title>

            <v-card-text>

              <!-- Número Celular -->
              <v-row>
                <v-col cols="12" md="6">
                  <!-- Campo de selección de país -->
                  <v-select
                    v-model="selectedCountry"
                    :items="countries"
                    label="País"
                    small-chips
                    item-title="nombre"
                    item-value="indicativo"
                    outlined
                    :disabled="estadoBoton"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <!-- Campo de número de celular -->
                  <v-text-field
                    v-model="phone"
                    label="Número Celular"
                    outlined
                    required
                    inputmode="numeric"
                    pattern="[0-9]*"
                    @input="limitarLongitud"
                    :disabled="estadoBoton"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-card-text>

            <!-- RESPUESTA Y BOTÓN RECUPERAR CUENTA -->
            <v-card-actions>
              <v-btn
                color="warning"
                :disabled="estadoBoton || phone == '' || phone.length < 10"
                @click="recuperarCuenta"
                block
              >
                Recuperar Cuenta
              </v-btn>
            </v-card-actions>

            <v-card-actions v-if="estadoBoton">
              <v-progress-linear
                class="mt-2"
                indeterminate
                color="warning"
              ></v-progress-linear>
              <span>{{ respuesta }}</span>
            </v-card-actions>

            <!-- Correo electrónico parcial -->
            <v-card-actions v-if="partialEmail">
              <v-divider></v-divider>
              <span>Se ha enviado al correo: {{ partialEmail }}</span>
            </v-card-actions>

          </v-card>

          <!-- BOTONERA -->
          <v-row class="buttons mt-5">
            <v-col>
              <v-btn
                color="warning"
                outlined
                @click="goToLogin"
                block
              >
                INICIO
              </v-btn>
            </v-col>
          </v-row>
          <!-- FIN BOTONERA -->

        </v-form>
      </v-col>
    </v-row>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </v-container>
</template>
    
<script>
import Whatsapp from "@/components/Whatsapp.vue";
import { recuperarContrasenaPorTelefono } from "@/config/registro";
import CabezoteA from "@/views/CabezoteA.vue";

export default {
  name: "RecuperarCuentaForm",

  components: { Whatsapp, CabezoteA },

  data() {
    return {
      email: null,
      phone: "",
      selectedCountry: "1", // Establece el valor de selectedCountry en "1"
      countries: [
        {
          nombre: "Estados Unidos",
          indicativo: "1",
          bandera: "🇺🇸",
          select: true,
        },
        { nombre: "Colombia", indicativo: "57", bandera: "🇨🇴", select: false },
      ],
      estadoBoton: false,
      respuesta: "",
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async recuperarCuenta() {
      this.estadoBoton = true;
      this.respuesta = "Procesando la solicitud";

      let numero = this.selectedCountry + this.phone;

      const resultLogin = await recuperarContrasenaPorTelefono(numero);

      //console.log(resultLogin);
      if (resultLogin[0]) {
        let correoTrans = this.correoTran(resultLogin[2]);
        this.respuesta =
          "Se ha envia al correo de registro las instrucciones." + correoTrans;

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      } else {
        this.respuesta = "El proceso ha fallado contacte al Administrador.";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      }
    },

    correoTran(correo) {
      if (correo) {
        const firstThree = correo.substring(0, 3);
        const atIndex = correo.indexOf("@");
        if (atIndex !== -1) {
          return `${firstThree}...${correo.substring(atIndex)}`;
        } else {
          return `${firstThree}...`;
        }
      }
      return "";
    },

    limitarLongitud() {
      if (this.phone.length > 9) {
        this.phone = this.phone.slice(0, 10);
      }
    },

  },
  computed: {
    partialEmail() {
      if (this.email) {
        const firstThree = this.email.substring(0, 3);
        const atIndex = this.email.indexOf("@");
        if (atIndex !== -1) {
          return `${firstThree}...${this.email.substring(atIndex)}`;
        } else {
          return `${firstThree}...`;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style>
    