<template>
  <div>
    <div v-if="desserts">
      <v-data-table
      fixed-header
        class="elevation-2"
        :headers="headers"
        :items="desserts"
        style="background-color: #f0f0f0; border-radius: 10px"
        height="500"
      >
        <template v-slot:[`item.fechaRegistro`]="{ item }">
          <v-chip
            class="ma-2 mt-3"
            text-color="black"
            variant="outlined"
            color="teal-darken-4"
          >
            {{ convertirTimestampAFechaHora(item.fechaRegistro) }}
          </v-chip>
        </template>

        <template v-slot:[`item.rentabilidadTotal`]="{ item }">
          <div style="text-align: end;" class="ma-2 mt-3">
            <v-chip :color="getColor(item.rentabilidadTotal)">
              {{ formatCurrency(item.rentabilidadTotal) }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.mes`]="{ item }">
          <div style="text-align: center" class="ma-2 mt-3">{{ item.mes }}</div>
        </template>

        <template v-slot:[`item.tipoMovimiento`]="{ item }">
          <div style="text-align: center" class="ma-2 mt-3">
            {{ item.tipoMovimiento === "Inversion" ? "Inversión" : item.tipoMovimiento }}
          </div>
        </template>

        <template v-slot:[`item.porcentajeGanancia`]="{ item }">
          <div style="text-align: center" class="ma-2 mt-3">{{ item.porcentajeGanancia }} %</div>
        </template>

        <template v-slot:[`item.dias`]="{ item }">
          <div style="text-align: center" class="ma-2 mt-3">{{ item.dias }} </div>
        </template>


        <template v-slot:[`item.estadoAdmin`]="{ item }">
          <div v-if="item.estadoAdmin" class="ma-2 mt-3">
            <v-icon color="green" icon="fas fa-check" />
          </div>
          <div v-else class="ma-2 mt-3">
            <v-icon icon="fas fa-stop-circle" />
          </div>
        </template>

        <!--
        <template v-slot:[`item.datos.descripcion`]="{ item }">
          {{ item.datos.descripcion }}
        </template>
  
        <template v-slot:[`item.datos.investment`]="{ item }">
          <div class="vertical-center">
                <v-chip :color="getColor(item.datos.investment)">
                  {{ formatCurrency(item.datos.investment) }}
                </v-chip>
              </div>
        </template>
  
        <template v-slot:[`item.datos.fechaRegistro`]="{ item }">
          {{ convertirTimestampAFechaHora(item.datos.fechaRegistro) }}
        </template>
  
        <template v-slot:[`item.datos.fuente`]="{ item }">
          {{ item.datos.fuente }}
        </template>
  
        <template v-slot:[`item.datos`]="{ item }">
          {{ formatFirebaseTimestamp(item.datos.fechaRegistro) }}
        </template>
  
        <template v-slot:[`item.datos.estadoAdmin`]="{ item }">
          <div v-if="item.datos.estadoAdmin">
            <v-icon color="green" icon="fas fa-check" />
          </div>
          <div v-else>
            <v-icon icon="fas fa-stop-circle" />
          </div>
        </template>
       -->

        <!-- Desplegable
        <template v-slot:expanded-row="{ columns, item }">
          <tr>
            <td :colspan="columns.length">
              <v-table
                :colspan="columns.length"
                v-if="item.datos.rentabilidadMensual"
                style="background-color: #fff0d5"
                fixed-header
                height="300px"
              >
                <thead>
                  <tr>
                    <th class="text-left">Fecha Registro</th>
                    <th class="text-left">Mes</th>
                    <th class="text-left">Tipo de Movimiento</th>
                    <th class="text-left">Rentabilidad</th>
                    <th class="text-left">Días</th>
                    <th class="text-left">Porcentaje</th>
                    <th class="text-left">Estado</th>
                    <th class="text-left">Pago</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in item.datos.rentabilidadMensual"
                    :key="index"
                  >
                    <td>
                      {{ convertirTimestampAFechaHora(item.fechaRegistro) }}
                    </td>
                    <td>{{ item.mes }}</td>
                    <td>{{ item.tipoMovimiento }}</td>
                    <td style="text-align: end">
                      <v-chip :color="getColor(item.rentabilidadTotal)">
                        {{ formatCurrency(item.rentabilidadTotal) }}
                      </v-chip>
                    </td>
                    <td style="text-align: center">{{ item.dias }}</td>
                    <td style="text-align: center">
                      {{ item.porcentajeGanancia }} %
                    </td>
                    <td>
                      <div v-if="item.estadoAdmin">
                        <v-icon color="green" icon="fas fa-check" />
                      </div>
                      <div v-else>
                        <v-icon icon="fas fa-stop-circle" />
                      </div>
                    </td>
                    <td>
                      <div v-if="item.pago">
                        <v-icon color="green" icon="fas fa-check" />
                      </div>
                      <div v-else>
                        <v-icon icon="fas fa-stop-circle" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </td>
          </tr>
        </template> -->
      </v-data-table>
    </div>
  </div>
</template>

  

<script>
import { VDataTable } from "vuetify/labs/VDataTable";

export default {
  name: "tablaPrincipal",
  components: {
    VDataTable,
  },

  props: {
    datoTransaccion: Array,
  },

  data: () => ({
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    headers: [
      {
        title: "Fecha Transacción",
        align: "center",
        key: "fechaRegistro",
        sortable: true,
      },

      { title: "Mes", key: "mes", sortable: true },
      { title: "Movimiento", key: "tipoMovimiento" },
      { title: "Monto", key: "rentabilidadTotal", sortable: true },
      { title: "Dias", key: "dias" },
      { title: "Porcentaje", key: "porcentajeGanancia" },
      { title: "Estado", key: "estadoAdmin" },
    ],
    desserts: [],
    datos: null,

    selected: [],
    expanded: [],
  }),

  mounted() {
    //console.log(this.datoTransaccion);

    this.datos = this.datoTransaccion[0];
    //this.desserts = this.datos;
    //this.totalItems = this.desserts.length; // Actualiza el total de elementos

    this.desserts = this.datos.datos.rentabilidadMensual || [];
    this.totalItems = this.desserts.length; // Actualiza el total de elementos

    //console.log(this.desserts);
  },

  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  watch: {
    selected(value) {
      if (value && value[0] && value[0].id !== undefined) {
        //console.log(value[0].id);
        let json = {
          id: value[0].id,
          posicion: value[0].posicion,
          investment: value[0].datos.investment,
          rentabilidadTotal: value[0].datos.rentabilidadTotal,
        };

        this.$emit("someEvent", json);
      } else {
        //console.log('La propiedad "id" no está definida o el objeto es undefined.');
        this.$emit("someEvent", null);
      }
    },
  },

  methods: {
    changePage() {
      // Lógica para cargar los elementos de la página actual
      // Puedes hacer una solicitud de API, por ejemplo
      console.log(`Cambiando a la página ${this.currentPage}`);
    },

    updateTotalPages(value) {
      this.totalItems = value;
    },

    convertirTimestampAFechaHora(timestamp) {
      const fecha = new Date(timestamp.seconds * 1000); // Multiplicamos por 1000 para convertir segundos a milisegundos
      return fecha.toLocaleString(); // Retorna la fecha en formato legible de acuerdo a la configuración regional
    },

    formatFirebaseTimestamp(timestamp) {
      // Convierte los segundos a milisegundos
      const milliseconds = timestamp.seconds * 1000;

      // Crea una nueva instancia de Date con los milisegundos
      const date = new Date(milliseconds);

      // Obtiene los componentes de la fecha
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      // Formatea el mes/año
      const formattedDate = `${month}/${year}`;

      return formattedDate;
    },

    formatCurrency(numberLlega) {
      // Si ya es un número, no lo conviertas
      const number =
        typeof numberLlega === "number" ? numberLlega : parseFloat(numberLlega);

      // Verificar si el número es menor que 1 y ajustar el formato
      if (number < 1) {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        return number.toLocaleString("en-EN", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },

    getColor(valores) {
      if (valores < 0) return "red"; // Si es negativo, se muestra en rojo
      else if (valores > 0)
        return "green"; // Si es positivo, se muestra en verde
      else return "orange"; // Si es cero, se muestra en naranja u otro color deseado
    },
  },
};
</script>

<style scoped>
.my-header-style {
  background-color: #f8d07e;
}

.even-row {
  background-color: #ffedc9; /* Color para filas pares */
}

.odd-row {
  background-color: #fff; /* Color para filas impares */
}

.vertical-center {
  vertical-align: middle;
  text-align: end;
}
</style>

