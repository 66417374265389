<template>
  <v-container class="mt-10">
    <CabezoteA h2="Bienvenido" title="Inversiones Beraca BG" />

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8" lg="6">
        <!-- FORMULARIO DE INGRESO -->
        <v-form @submit.prevent="recaptcha">
          <v-card elevation="2">
            <v-card-title>
              <h3 class="title">Bienvenido</h3>
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="email"
                label="Email"
                type="email"
                outlined
                required
                :disabled="estadoBoton"
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                outlined
                required
                :disabled="estadoBoton"
                append-icon="fa fa-eye"
                @click:append="togglePassword"
              ></v-text-field>

              <!-- BOTÓN -->
              <v-btn
                class="mt-5"
                color="warning"
                :disabled="estadoBoton || email == '' || password.length < 5"
                @click="login"
              >
                Ingresar
              </v-btn>
            </v-card-text>

            <!-- RESPUESTA -->
            <v-card-actions v-if="respuesta">
              <v-progress-linear
                class="mt-2"
                indeterminate
                color="warning"
              ></v-progress-linear>
              <span>{{ respuesta }}</span>
            </v-card-actions>
          </v-card>

          <!-- BOTONERA -->
          <v-row class="buttons mt-5" justify="center">
            <v-col cols="12" md="6" lg="4" class="mt-3 mt-md-0">
              <v-btn
                color="warning"
                class="white--text"
                @click="goToRegister"
                block
              >
                Registrarse
              </v-btn>
            </v-col>

            <v-col cols="12" md="6" lg="4" class="mt-3 mt-md-0">
              <v-btn
                color="warning"
                class="white--text"
                @click="goToRecuperarPassword"
                block
              >
                Restablecer
              </v-btn>
            </v-col>

            <v-col cols="12" md="6" lg="4" class="mt-3 mt-lg-0">
              <v-btn
                color="warning"
                class="white--text"
                @click="goToRecuperarCuenta"
                block
              >
                Recuperar
              </v-btn>
            </v-col>
          </v-row>
          <!-- FIN BOTONERA -->

        </v-form>
      </v-col>
    </v-row>
    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </v-container>
</template>
  
<script>
import { useReCaptcha } from "vue-recaptcha-v3";
//import CapC from "@/components/ReCap.vue";
import CabezoteA from "@/views/CabezoteA.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { LoginFB } from "@/config/registro";
import { useStore } from "vuex";

export default {
  name: "LoginForm",

  components: { Whatsapp, CabezoteA },

  data() {
    return {
      store: useStore(),
      email: "",
      password: "",
      respuesta: null,
      estadoBoton: false,
      recaptchaLoaded: false,
      showPassword: false,
    };
  },

  mounted() {
    this.$recaptcha = useReCaptcha();
    this.loadRecaptcha();
  },

  methods: {
    //reCaptcha
    async executeRecaptchaAction() {
      if (!this.recaptchaLoaded) {
        await this.loadRecaptcha();
      }

      try {
        const { executeRecaptcha } = this.$recaptcha;
        const token = await executeRecaptcha("login");

        if (token) {
          // El reCAPTCHA se completó correctamente y se generó un token
          //console.log("Token generado:", token);
          //this.$emit("recaptcha", true);
          this.login();
        } else {
          // El reCAPTCHA no se completó correctamente
          console.log(
            "El reCAPTCHA no se completó correctamente. Inténtalo de nuevo."
          );
          //this.$emit("recaptcha", false);this.respuesta = "La contraseña no cumple con los requisitos...";

          this.respuesta =
            "El reCAPTCHA no se completó correctamente. Inténtalo de nuevo.";
          setTimeout(() => {
            this.respuesta = null;
            this.email = "";
            this.password = "";
            this.estadoBoton = false;
          }, 2000); // 3000 milisegundos = 3 segundos
        }
      } catch (error) {
        console.error("Error en la ejecución de reCAPTCHA:", error);
        //this.$emit("recaptcha", false);
        this.respuesta =
          "El reCAPTCHA no se completó correctamente. Contacte al Administrador.";
        setTimeout(() => {
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.estadoBoton = false;
        }, 2000); // 3000 milisegundos = 3 segundos
      }
    },

    async loadRecaptcha() {
      const { recaptchaLoaded } = this.$recaptcha;
      await recaptchaLoaded();
      this.recaptchaLoaded = true;
    },

    recaptcha() {
      if (!this.email || !this.password) {
        // Al menos uno de los campos está vacío, retornar vacío
        return;
      }

      // Validar el formato del correo electrónico
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.email)) {
        // El correo electrónico no tiene un formato válido, retornar vacío
        this.respuesta = "El correo electrónico no tiene un formato válido...";

        setTimeout(() => {
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.estadoBoton = false;
        }, 2000); // 3000 milisegundos = 3 segundos
        return;
      }

      // Validar la contraseña
      const passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{6,}$/;
      if (!passwordPattern.test(this.password)) {
        // La contraseña no cumple con los requisitos, retornar vacío
        this.respuesta = "La contraseña no cumple con los requisitos...";
        setTimeout(() => {
          this.respuesta = null;
          this.email = "";
          this.password = "";
          this.estadoBoton = false;
        }, 2000); // 3000 milisegundos = 3 segundos

        return;
      }

      this.executeRecaptchaAction();
    },

    //end reCaptcha

    goToRegister() {
      this.$router.push({ name: "Register" });
    },

    goToRecuperarPassword() {
      this.$router.push({ name: "RecuperarPassword" });
    },

    goToRecuperarCuenta() {
      this.$router.push({ name: "RecuperarCuenta" });
    },

    async login() {
      this.estadoBoton = true;
      this.respuesta = "Procesando...";

      const resultLogin = await LoginFB(this.email, this.password);

      if (resultLogin[0]) {
        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.store.dispatch("doLogin", resultLogin[1]);
          this.$router.push({ name: "Dashboard" });
        }, 3000); // 3000 milisegundos = 3 segundos

        this.respuesta = "Estamos direccionandole a tu perfil...";
      } else {
        //console.log("Contraseña o Correo Inválido");

        if (resultLogin[1] == "auth/wrong-password") {
          this.respuesta = "Los datos no son correctos, intente de nuevo.";
          setTimeout(() => {
            this.respuesta = null;
            this.email = "";
            this.password = "";
            this.estadoBoton = false;
          }, 3000); // 3000 milisegundos = 3 segundos
        } else {
          // "auth/user-not-found"
          this.respuesta = "Los datos no son correctos, intente de nuevo.";
          setTimeout(() => {
            this.respuesta = null;
            this.email = "";
            this.password = "";
            this.estadoBoton = false;
          }, 3000); // 3000 milisegundos = 3 segundos
        }
      }

      //this.respuesta = null;
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.centered-content {
  text-align: center; /* Para centrar horizontalmente el contenido */
  display: flex;
  flex-direction: column;
  align-items: center; /* Para centrar verticalmente el contenido */
}
/* Estilos para la respuesta */
.respuesta {
  display: block;
  text-align: center;
  margin-top: 10px;
  /* color: hsl(171, 100%, 41%); Cambia el color a tu preferencia */
}

.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.clickable-icon {
  cursor: pointer;
  position: absolute;
  top: 30%;
  right: 10px;
  transform: translateY(-50%);
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media screen and (max-width: 768px) {
  .is-fullwidth-mobile {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .reduce-margin-bottom-mobile {
    /*margin-bottom: 200px;  Ajusta el valor según sea necesario */
    margin-top: -20px;
  }
}

/* Estilos para dispositivos no móviles */
@media screen and (min-width: 769px) {
  .buttons .centered-content {
    margin-right: 10px; /* Espaciado entre columnas, ajusta según sea necesario */
  }
  .buttons .centered-content:last-child {
    margin-right: 0;
  }
}
</style>
  