<template>
  <v-container class="mt-10">
    <CabezoteA h2="Restablecer Password" title="Digite su correo de registro" />

    <v-row justify="center" class="mt-5">
      <v-col cols="12" md="8" lg="6">
        <!-- FORMULARIO DE RECUPERACIÓN DE CONTRASEÑA -->
        <v-form @submit.prevent="recuperarCuenta">
          <v-card elevation="2">
            <v-card-title>
              <h3 class="title">Restablecer Password</h3>
            </v-card-title>

            <v-card-text>
              <!-- Correo Electrónico -->
              <v-text-field
                v-model="email"
                label="Correo Electrónico"
                outlined
                type="email"
                required
                :disabled="estadoBoton"
              ></v-text-field>
            </v-card-text>

            <!-- RESPUESTA -->
            <v-card-actions v-if="estadoBoton">
              <v-progress-linear
                class="mt-2"
                indeterminate
                color="warning"
              ></v-progress-linear>
              <span>{{ respuesta }}</span>
            </v-card-actions>

            <!-- BOTÓN RECUPERAR CONTRASEÑA -->
            <v-card-actions>
              <v-btn
                color="warning"
                :disabled="estadoBoton || email == '' || email.length < 9"
                @click="recuperarCuenta"
                block
              >
                Recuperar Contraseña
              </v-btn>
            </v-card-actions>
          </v-card>

          <!-- BOTONERA -->
          <v-row class="buttons mt-5">
            <v-col>
              <v-btn color="warning" outlined @click="goToLogin" block>
                INICIO
              </v-btn>
            </v-col>
          </v-row>
          <!-- FIN BOTONERA -->
        </v-form>
      </v-col>
    </v-row>

    <!-- Botón flotante en la parte superior derecha -->
    <whatsapp />
  </v-container>
</template>
    
<script>
import CabezoteA from "@/views/CabezoteA.vue";
import Whatsapp from "@/components/Whatsapp.vue";
import { recuperarContrasenaFB } from "@/config/registro";

export default {
  name: "LoginForm",

  components: { Whatsapp, CabezoteA },

  data() {
    return {
      email: "",
      estadoBoton: false,
      respuesta: "",
    };
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "Login" });
    },

    async recuperarCuenta() {
      this.estadoBoton = true;
      this.respuesta = "Generando la petición";

      const resultLogin = await recuperarContrasenaFB(this.email);
      //console.log(resultLogin);

      if (resultLogin[0]) {
        this.respuesta = "Se ha enviado al correo la solicitud";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      } else {
        this.respuesta = "Se ha enviado al correo la petición";

        setTimeout(() => {
          this.respuesta = null;
          // Llama a la acción del store para iniciar sesión y almacenar los datos
          this.estadoBoton = false;
          this.$router.push({ name: "Login" });
        }, 3000); // 3000 milisegundos = 3 segundos
      }
    },
  },
};
</script>

<style scoped>
.floating-button-whatsapp {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.rounded-box {
  border-radius: 30px;
  background: linear-gradient(to bottom, #131313, #3b3b3b);
  padding: 20px; /* Ajusta el relleno según sea necesario */
}

/* Estilos para los textos dentro del contenedor */
.rounded-box h3.title {
  color: #f8d07e; /* Color del texto para h3 */
  font-size: 24px; /* Tamaño de fuente para h3 */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box h6.subtitle {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 18px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box label {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.rounded-box span {
  color: #aaaaaa; /* Color del texto para h6 */
  font-size: 13px; /* Tamaño de fuente para h6 */
  margin-top: 10px; /* Ajusta el margen superior según sea necesario */
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
</style>
    