import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import RecuperarPassword from '../components/RecuperarPassword.vue'
import RecuperarCuenta from '../components/RecuperarCuenta.vue'
import Dashboard from '../components/Dashboard/Dashboard.vue'

import DashboardPerfil from '../components/Dashboard/perfil/Perfil.vue'
import DashboardCalculadora from '../components/Dashboard/calculadora/Calculadora.vue'
import DashboardTransacciones from '../components/Dashboard/transacciones/Transacciones.vue'
import DashboardNoticias from '../components/Dashboard/noticias/Noticias.vue'


import DashboardAdminUsuarios from '../components/Dashboard/admin/Usuarios.vue'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register,
  },
  {
    path: '/recuperar-password',
    name: 'RecuperarPassword',
    component: RecuperarPassword,
  },
  {
    path: '/recuperar-cuenta',
    name: 'RecuperarCuenta',
    component: RecuperarCuenta,
  },
  {
    path: '/inversiones',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: DashboardPerfil,
  },
  {
    path: '/calculadora',
    name: 'Calculadora',
    component: DashboardCalculadora,
  },
  {
    path: '/transacciones',
    name: 'Transacciones',
    component: DashboardTransacciones,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: DashboardAdminUsuarios,
  },
  {
    path: '/comunicados',
    name: 'Comunicados',
    component: DashboardNoticias,
  },
  

  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
